<template>
  <span>
    <AiInput
      :label="$t('email')"
      v-model="email"
      :placeholder="$t('placeholderEmail')"
      prop="email"
      :error="emailError"
      :maxlength="50"
      @onClearError="onClearError"
    />
    <div class="login-btn-wrap">
      <div class="login-btn" @click="!loginLoading ? handleLogin() : ''">
        {{ $t("changeTitle") }}
      </div>
    </div>
  </span>
</template>
<script>
import AiInput from "@/components/Input/Index.vue";
import { isEmail } from "@/utils/reg_exp.js";
export default {
  name: "ChangePassword",
  components: {
    AiInput,
  },
  props: {},
  watch: {},
  data() {
    return {
      email: "",
      emailError: "",
    };
  },
  computed: {
    loginLoading() {
      return this.$store.state.loginLoading;
    },
  },
  mounted() {},
  methods: {
    onClearError() {
      this.emailError = "";
    },
    async handleLogin() {
      this.emailError = "";
      if (!isEmail(this.email)) {
        this.$set(this, "emailError", this.$t("placeholderEmail"));
      } else {
        this.$set(this, "emailError", "");
      }
      if (this.emailError) {
        return;
      }
      // 直接调用邮箱找回接口
      const params = {
        email: this.email,
      };
      const res = await this.$store.dispatch(
        "postUserSendResetPassword",
        params
      );
      if (res.status === "200") {
        if (res.code === "12009") {
          this.$toast({
            type: "error",
            message: res.msg,
            duration: 5,
          });
        } else {
          this.$store.commit("setShowLogin", false);
          this.$toast({
            type: "success",
            message: this.$t("revisePassword", { num: this.email }),
            duration: 8,
          });
          let that = this;
          var timerId = setTimeout(function () {
            that.$store.commit("setLoginStatus", "SignIn");
            clearTimeout(timerId);
          }, 300);
        }
      } else {
        this.$toast({
          type: "error",
          message: res.msg,
          duration: 5,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login-btn-wrap {
  padding-top: 7px;
  .login-btn {
    cursor: pointer;
    width: 100%;
    height: 39px;
    background: #111111;
    border-radius: 71px;
    font-size: 12px;
    font-family: PingFangTC-Medium, PingFangTC;
    font-weight: 500;
    color: #fff;
    text-align: center;
    line-height: 39px;
  }
}
</style>
