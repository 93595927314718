import axios from "@/common/http";
import { LocalDataUtil } from "@/common/util";
export default {
  state: () => ({
    productTaskList: [],
    recordLoading: false,
    selectEmojiStyleData: {},
    recordGeneralList: {}, //历史生成记录,多条
    recordDetailedData: [], // 记录详情
  }),
  mutations: {
    setProductTaskList(state, list) {
      state.productTaskList = list;
    },
    deleteProductTask(state, pack) {
      const productTaskList = state.productTaskList;
      let startIndex = -1;
      for (let i = 0; i < productTaskList.length; i++) {
        if (productTaskList[i].pack_id === pack.pack_id) {
          startIndex = i;
          continue;
        }
      }
      state.productTaskList.splice(startIndex, 1);
    },
    setRecordListLoading(state, value) {
      state.recordLoading = value;
    },
    setEmojiStyleData(state, data) {
      state.selectEmojiStyleData = data;
      LocalDataUtil.setItem("selectEmojiStyleData", JSON.stringify(data));
    },
    setRecordGeneralList(state, data) {
      state.recordGeneralList[data.type] = data.data;
    },
    setRecordDetailedData(state, data) {
      state.recordDetailedData = data;
    },
  },
  actions: {
    /**
     *
     * @desc 查询用户创建的任务列表
     * @returns
     */
    async productTaskList({ commit }) {
      const res = await axios.post("/v1/aiemoji/pack/process");
      commit("setProductTaskList", res.data);

      return res;
    },
    // 删除pack  pack_id     preset_id为空的时候将删除整个pack
    async deleteTask({ commit }, data) {
      const res = await axios.post("/v1/aist/pack/delete", data);
      const pack = res.data;
      commit("deleteProductTask", pack);
      return res;
    },
    // 0 头像 1 标签
    async getRecordGeneralList({ commit }, type = 0) {
      console.log("getRecordGeneralList", type);
      let params = {
        ai_works_type: type,
      };
      commit("setRecordListLoading", true);
      const res = await axios.post("/v1/aiemoji/pack/list", params);
      commit("setRecordGeneralList", { data: res.data, type });
      commit("setRecordListLoading", false);

      return res;
    },
    /**
     * @desc 生成纪录详情图片列表
     * @returns
     */
    async getRecordDetailList({ commit }, data) {
      commit("setRecordDetailedData", []);
      commit("setRecordListLoading", true);
      const res = await axios.post("/v1/aiemoji/image/list", data);
      commit("setRecordListLoading", false);
      if (res.status === "200") {
        commit("setRecordDetailedData", res.data);
      }
      return res;
    },
  },
  getters: {},
};
