var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('button',{class:{
      'ai-btn': true,
      'btn-black': _vm.type === 'primary',
      'btn-white': _vm.type === 'default',
      'btn-blue': _vm.type === 'blue',
      'ai-btn-disabled': _vm.disabled || _vm.loading,
    },style:({
      width: `${_vm.width}px`,
      height: `${_vm.height}px`,
      lineHeight: `${_vm.height}px`,
      borderRadius: `${_vm.radius}px`,
      fontSize: `${_vm.fontSize}px`,
    }),attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default"),(_vm.loading)?_c('div',{staticClass:"btn-loading"}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }