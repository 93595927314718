import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import ObtainCodeView from "../views/ObtainCodeView.vue";
import { isLogin } from "@/common/util";
import { trackPage } from "@/lib/kewlSensor/pageViewSensor";
import { LocalDataUtil } from "@/common/util";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: "home",
      auth: false,
    },
    component: ObtainCodeView,
  },
  {
    path: "/edit",
    name: "edit",
    meta: {
      title: "",
      auth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "edit" */ "../views/EditView.vue"),
    children: [
      {
        path: "",
        redirect: "community",
      },
      {
        path: "generation",
        name: "generation",
        meta: {
          title: "generation",
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "artface" */
            "../views/lmageGenerationView.vue"
          ),
      },
      // {
      //   path: "artface",
      //   name: "artface",
      //   meta: {
      //     title: "artface",
      //     auth: true,
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "artface" */ "../views/ArtFaceView.vue"),
      // },
      // {
      //   path: "emoji",
      //   name: "emoji",
      //   meta: {
      //     title: "emoji",
      //     auth: true,
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "artface" */ "../views/EmojiView.vue"),
      // },
      // {
      //   path: "record",
      //   name: "record",
      //   meta: {
      //     title: "record",
      //     auth: true,
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "record" */ "../views/RecordView.vue"),
      // },
      {
        path: "liked",
        name: "liked",
        meta: {
          title: "liked",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "record" */ "../views/LikedFeefView.vue"),
      },
      {
        path: "community",
        name: "community",
        meta: {
          title: "community",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "record" */ "../views/CommunityView.vue"),
      },
      {
        path: "notifications",
        name: "notifications",
        meta: {
          title: "notifications",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "record" */ "../views/NoticeView.vue"),
      },
      {
        path: "invite",
        name: "invite",
        meta: {
          title: "invite",
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "record" */ "../views/InviteFriendsView.vue"
          ),
      },
    ],
  },
  // {
  //   path: "/record_detailed",
  //   name: "record_detailed",
  //   meta: {
  //     title: "record_detailed",
  //     auth: true,
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "record_detailed" */
  //       "../views/RecordDetailedView.vue"
  //     ),
  // },
  // {
  //   path: "/payment",
  //   name: "payment",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "record_detailed" */
  //       "../views/PaymentView.vue"
  //     ),
  // },
  {
    path: "/subscribed",
    name: "subscribed",
    meta: {
      title: "subscribed",
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "record_detailed" */
        "../views/SubscribedView.vue"
      ),
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // startTime = Date.now();
  let parm = JSON.stringify({ ...to.params, ...to.query });
  trackPage(0, from.path, parm); // 退出页面
  if (to.meta && to.meta.auth) {
    const isLoginData = isLogin();
    if (!isLoginData) {
      store.commit("setShowLogin", true);
      store.commit("setCurrentPath", "community");
      next({
        path: "/",
      });
    } else if (isLoginData === "inviteCode") {
      LocalDataUtil.removeItem("aispacewebtoken");
      LocalDataUtil.removeItem("aipacewebuserinfo");
      store.commit("setShowLogin", true);
      store.commit("setLoginStatus", "SignIn");
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next();
    // console.info(this);
  }
  // next();
});

router.afterEach((to, from) => {
  let parm = JSON.stringify({ ...to.params, ...to.query });
  trackPage(1, from.path, parm); // 进入
});

export default router;
