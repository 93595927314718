import axios from "@/common/http";
import Toast from "@/components/Toast/ToastIndex";
export default {
  state: () => ({
    communityLoading: false,
    communityModalData: {}, // 社区弹窗数据
    communityModalVisible: false, // 社区弹窗显示
  }),
  mutations: {
    setCommunityLoading: (state, value) => {
      state.communityLoading = value;
    },
    setCommunityModalData: (state, value) => {
      state.communityModalData = value;
    },
    setCommunityModalVisible: (state, value) => {
      state.communityModalVisible = value;
    },
  },
  actions: {
    /**
     *@desc 获取社区列表图
     */
    async getCommunityList({ commit }, data) {
      commit("setCommunityLoading", true);
      const res = await axios.post("/v1/image/published", data);
      commit("setCommunityLoading", false);
      if (res.status === "200") {
        return res.data;
      }
    },
    /**
     *@desc 我喜欢的列表
     */
    async getMyLikeList({ commit }, data) {
      commit("setCommunityLoading", true);
      const res = await axios.post("/v1/my/like", data);
      commit("setCommunityLoading", false);
      if (res.status === "200") {
        return res.data || [];
      }
    },
    /**
     *@desc 点赞 || 取消点赞
     */
    async getCommunityLike({ commit, state }) {
      const params = {
        image_id: state.communityModalData.data.image_id,
        preset_id: state.communityModalData.data.preset_id,
        pic_url: state.communityModalData.data.url,
        preview_url: state.communityModalData.data.preview_url,
        type: state.communityModalData.data.liked ? 0 : 1,
      };
      state.communityModalData.data.liked =
        !state.communityModalData.data.liked;
      if (params["type"]) {
        state.communityModalData.data.like_count++;
      } else {
        state.communityModalData.data.like_count--;
      }
      const res = await axios.post("/v1/aiemoji/image/like", params);
      console.log(commit);
      if (res.status !== "200") {
        state.communityModalVisible = false;
        state.communityModalData.data.liked =
          !state.communityModalData.data.liked;
        if (params["type"]) {
          state.communityModalData.data.like_count--;
        } else {
          state.communityModalData.data.like_count++;
        }
      }
    },
    /**
     *@desc 发布到社区
     */
    async postCommunityPublish({ commit, state }, data) {
      state.communityLoading = true;
      const res = await axios.post("/v1/image/publish", data);
      console.log(commit);
      state.communityLoading = false;
      if (res.status === "200") {
        if (res.code) {
          Toast({
            type: "error",
            message: res.msg || res.data["0"] || "Service error",
          });
          return false;
        } else {
          Toast({
            type: "info",
            message: "success",
          });
          state.communityModalVisible = false;
          return true;
        }
      } else {
        return false;
      }
    },
    /**
     *@desc 消息通知
     */
    async postNotifications({ commit, state }, data) {
      state.communityLoading = true;
      const res = await axios.post("/v1/my/notifications", data);
      console.log(commit);
      state.communityLoading = false;
      return res;
    },
    /**
     *@desc 邀请列表
     */
    async postInvitefriends({ commit, state }, data) {
      state.communityLoading = true;
      const res = await axios.post("/v1/invitefriends/list", data);
      console.log(commit);
      state.communityLoading = false;
      return res;
    },
  },
  getters: {},
};
