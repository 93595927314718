<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import "@/assets/css/reset.css";
import "@/assets/css/globalStyles.css";
export default {
  name: "AppMain",
};
</script>

<style lang="less">
html,
body {
  min-height: 100%;
  width: 100%;
  overflow-x: auto;
  background: black;
  font-size: 14px;
}
body {
  min-width: 1080px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  background: black;
  user-select: none;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.is_mobile {
  min-width: auto;
  * {
    -webkit-tap-highlight-color: transparent;
  }
}
</style>
