// a 标签下载
// 仅支持同源
export const downloadImage = (fileUrl, fileName = "downloadImage") => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFile = (url) => {
  return new Promise((resolve) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    link.addEventListener("click", () => {
      setTimeout(() => {
        link.remove();
        resolve();
      }, 1000);
    });
    document.body.appendChild(link);
    link.click();
  });
};
