import Modal from "@/components/Modal/Index.vue";
import ImgLazy from "@/components/ImgLazy/Index.vue";
import AiButton from "@/components/Button/Index.vue";
import Loading from "@/components/Loading/loading.vue";

const plugins = {
  install(Vue) {
    Vue.component("AiModal", Modal);
    Vue.component("AiImgLazy", ImgLazy);
    Vue.component("AiButton", AiButton);
    Vue.component("LoadingCom", Loading);
  },
};
export default plugins;
