export function getQueryByName(name) {
  const regex = new RegExp(`[?&]${name}=([^&#]*)`);
  const match = regex.exec(window.location.href);
  return match ? match[1] : null;
}

export const LocalDataUtil = {
  setItem: (key, value) => {
    if (value === undefined || value === null) {
      console.assertlog("setItem value is null");
      return;
    }
    if (window.localStorage) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      // set cookie
      LocalDataUtil.setCookie(key, value);
    }
  },
  getItem: (key) => {
    let value;
    if (window.localStorage) {
      value = localStorage.getItem(key);
    } else {
      value = LocalDataUtil.getCookie(key);
    }

    if (value) {
      return JSON.parse(value);
    }
    return null;
  },
  removeItem: (key) => {
    if (window.localStorage) {
      localStorage.removeItem(key);
    } else {
      LocalDataUtil.getCookie(key);
    }
  },
  clearItem() {
    localStorage.clear();
  },
  setCookie: (name, value) => {
    const Days = 1;
    const exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + escape(value) + ";expires=" + exp.toUTCString();
  },
  getCookie: (name) => {
    let arr,
      reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if ((arr = document.cookie.match(reg))) {
      return unescape(arr[2]);
    } else {
      return null;
    }
  },
  delCookie: (name) => {
    const exp = new Date();
    exp.setTime(exp.getTime() - 1);
    const cval = this.getCookie(name);
    if (cval != null) {
      document.cookie = name + "=" + cval + ";expires=" + exp.toUTCString();
    }
  },
};

export function downloadFileByUrl(url) {
  const urlSplitArr = url.split("/");
  const filename = urlSplitArr[urlSplitArr.length - 1];
  window
    .fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      downloadFile(blob, filename);
    });
}
function downloadFile(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  // 创建隐藏的可下载链接
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function isLogin() {
  const whetherLogin =
    LocalDataUtil.getItem("aispacewebtoken") &&
    LocalDataUtil.getItem("aipacewebuserinfo");
  if (!whetherLogin) {
    LocalDataUtil.removeItem("aispacewebtoken");
    LocalDataUtil.removeItem("aipacewebuserinfo");
  } else {
    const info = JSON.parse(LocalDataUtil.getItem("aipacewebuserinfo"));
    if (!info || !info.user_id || !info.nickname || !info.avatar) {
      LocalDataUtil.clearItem();
      return false;
    }
    if (!info.invited) {
      return "inviteCode";
    }
  }
  return whetherLogin;
}

// 删除URl多个参数
export const delUrlParam = (arr) => {
  const url = window.location.href;
  const parsedUrl = new URL(url);
  for (const iterator of arr) {
    parsedUrl.searchParams.delete(iterator);
  }
  const updatedUrl = parsedUrl.href;
  window.history.replaceState(null, "", updatedUrl);
};
