<template>
  <div class="invitation-code">
    <AiInput
      v-model="code"
      :placeholder="$t('placeholderCode')"
      prop="code"
      :error="codeError"
      :maxlength="6"
      @onClearError="onClearError"
    />
    <div class="login-btn-wrap">
      <div class="login-btn" @click="handleInvitefriends">
        {{ $t("confirm") }}
      </div>
    </div>
    <div class="replace" @click="handleSwitchAccount">
      {{ $t("switchAccount") }}
    </div>
  </div>
</template>
<script>
import AiInput from "@/components/Input/Index.vue";
import { LocalDataUtil } from "@/common/util";
export default {
  name: "InvitationCode",
  components: {
    AiInput,
  },
  props: {},
  watch: {},
  data() {
    return {
      code: "",
      codeError: "",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    handleSwitchAccount() {
      LocalDataUtil.removeItem("aispacewebtoken");
      LocalDataUtil.removeItem("aipacewebuserinfo");
      this.$store.commit("setLoginStatus", "SignIn");
    },
    onClearError() {
      this.codeError = "";
    },
    async handleInvitefriends() {
      this.codeError = "";
      const params = {
        register_code: this.code,
      };
      const res = await this.$store.dispatch("postInvitefriendsAdd", params);
      if (res.status === "200") {
        this.$toast({
          type: "success",
          message: this.$t("success"),
          duration: 5,
        });
        let data = LocalDataUtil.getItem("aipacewebuserinfo");
        let userInfo = data ? JSON.parse(data) : {};
        userInfo["invited"] = 1;
        LocalDataUtil.setItem("aipacewebuserinfo", JSON.stringify(userInfo));
        this.$store.commit("setLoginStatus", "SignIn");
        this.$store.commit("setShowLogin", false);
        this.$router.replace("edit/community");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invitation-code {
  padding-top: 30px;
}

.login-btn-wrap {
  padding-top: 7px;

  .login-btn {
    cursor: pointer;
    width: 100%;
    height: 39px;
    background: #111111;
    border-radius: 71px;
    font-size: 12px;
    font-family: PingFangTC-Medium, PingFangTC;
    font-weight: 500;
    color: #fff;
    text-align: center;
    line-height: 39px;
  }
}

.replace {
  width: 100%;
  height: 15px;
  font-size: 11px;
  font-weight: 400;
  color: rgba(106, 107, 107, 1);
  line-height: 15px;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  margin-top: 18px;
}
</style>
