import sensors from "sa-sdk-javascript";
import {
  generateDeviceId,
  getDeviceType,
  getSystemVersion,
  getConnectionType,
} from "../../utils/only";
import { copy } from "../../utils/index";
/**
 * @desc 同步加载神策代码
 */
// Grid_Editor_Test 测试 Grid_Editor 线上
const project =
  window.location.host === "www.aispace.ink" ||
  window.location.host === "www.aispark.ai"
    ? "Grid_Editor"
    : "Grid_Editor_Test";
// const showLog = project == "Grid_Editor_Test";
window.sensors = sensors;
const currentProtocol = window.location.protocol.split(":")[0];
sensors.init({
  name: "sensors",
  server_url: `${currentProtocol}://sensor.aiemoji.net/sa?project=${project}`,
  heatmap: {},
  show_log: false,
  use_client_time: true,
  send_type: "beacon",
});
// 神策自动跟踪 最好添加 页面加载后调用一次即可
window.sensors.quick("autoTrack");

/**
 * @desc 获取公共参数   ==============================
 */
const publicPram = {
  ai_dev_id: "", // 设备ID
  ai_mtype: "", // 设备型号
  ai_os: "", // 操作系统 1：安卓APP 2：IOS-APP 3：PC 4：安卓H5 5：IOS-H5
  ai_os_ver: "", // 系统版本
  ai_user_id: "", // 账号ID
  ai_web_is_first: "", // 是否首日访问
  ai_logtime: "", // 日志时间
  ai_net: "", // 网络状态
  ai_language: "", // 用户语言
  ai_display: "", // 分辨率
};

const ua = navigator.userAgent;
function initPublicParams() {
  const screenWidth = screen.width;
  const screenHeight = screen.height;
  const userInfoStr = localStorage.getItem("aipacewebuserinfo") || ``;
  const userInfo = userInfoStr ? JSON.parse(JSON.parse(userInfoStr)) : {};
  publicPram.ai_dev_id = generateDeviceId();
  publicPram.ai_mtype = ua;
  publicPram.ai_os = getDeviceType();
  publicPram.ai_os_ver = getSystemVersion();
  publicPram.ai_web_is_first = !!localStorage.getItem("deviceId");
  publicPram.ai_logtime = Date.now();
  publicPram.ai_net = getConnectionType();
  publicPram.ai_language = navigator.language || navigator.userLanguage;
  publicPram.ai_display = screenWidth + "x" + screenHeight;
  publicPram.ai_user_id = userInfo["user_id"] || "";
}

/**
 * @desc kewl 通用神策
 * 各业务需要使用神策埋点时，默认使用通用的神策公共参数，业务表参数和业务字段参数通过register方法注册
 * 上报过程只需要关注当前上报的字段即可，其他字段不上报默认使用初始化值;
 * @demo KEWLSensor.register('testTable', {key1:'',key2:0,key3: false}); KEWLSensor.track({key2: 1});
 */
const KEWLSensor = {
  version: "0.0.1",
  _tableName: [],
  _businessPram: [], //  [{tablename: {params}},{tablename: {params}}]
  businessPram: [], //  [{tablename: {params}},{tablename: {params}}]
  _tableName2: "",
  _businessPram2: "", //  [{tablename: {params}},{tablename: {params}}]
  businessPram2: "", //  [{tablename: {params}},{tablename: {params}}]
  register(tableName, initPramas) {
    if (window.sensors) {
      initPublicParams(); // 公共参数初始化
      window.sensors.registerPage(publicPram); // 神策注册公共参数
      this._tableName.push(tableName);
      this.businessPram.push({
        tableName,
        params: initPramas,
      });
      const obj = {};
      this.businessPram = this.businessPram.reduce(function (item, next) {
        obj[next.tableName]
          ? ""
          : (obj[next.tableName] = true && item.push(next));
        return item;
      }, []);

      this._businessPram.push({
        tableName,
        params: copy(initPramas),
      });

      // 兼容历史情况 在track的时候不传递tablename情况 默认使用注册的besinesparam
      this._tableName2 = tableName;
      this.businessPram2 = initPramas;
      this._businessPram2 = copy(initPramas);
    }
  },
  track(tableName, params) {
    // 兼容不传递表名的情况
    if (arguments.length == 1 && typeof tableName === "object") {
      for (const action in this.businessPram2) {
        if (
          Object.prototype.hasOwnProperty.call(tableName, action) &&
          Object.prototype.hasOwnProperty.call(this.businessPram2, action)
        ) {
          this.businessPram2[action] = tableName[action];
        } else {
          this.businessPram2[action] = this._businessPram2[action];
        }
      }
      window.sensors.track(this._tableName2, this.businessPram2);
      return;
    }
    if (window.sensors && this._tableName.indexOf(tableName) > -1) {
      if (typeof params === "object") {
        for (let i = 0; i < this.businessPram.length; i++) {
          if (this.businessPram[i].tableName == tableName) {
            for (const business in this.businessPram[i].params) {
              if (
                Object.prototype.hasOwnProperty.call(params, business) &&
                Object.prototype.hasOwnProperty.call(
                  this.businessPram[i].params,
                  business
                )
              ) {
                this.businessPram[i].params[business] = params[business];
              } else {
                this.businessPram[i].params[business] =
                  this._businessPram[i].params[business];
              }
            }
            window.sensors.track(
              this.businessPram[i].tableName,
              this.businessPram[i].params
            );
          }
        }
      }
    }
  },
};

window.KEWLSensor = KEWLSensor;
export default KEWLSensor;
// // 使用示例
// KEWLSensors.register('lm_cashout', {
//   key: 'value',
//   key1: 'value1'
// });

// KEWLSensors.track({
//   key: 'valuenew'
// });
