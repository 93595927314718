// 邮箱正则表达式
export const isEmail = (email) => {
  var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

// 密码正则表达式
export const isPassword = (password) => {
  // 密码正则表达式（至少包含一个数字和一个字母，不区分大小写）
  var regex = /^(?=.*\d)(?=.*[a-zA-Z]).{6,18}$/i;
  return regex.test(password);
};
