/**
 * 时间戳转换,支持数字和字符串类型时间戳
 */
export function convertTime(timestamp) {
  if (timestamp) {
    var reg = /^\d+$/;
    if (typeof timestamp === "number" || reg.test(timestamp)) {
      var n = timestamp * 1000;
      var date = new Date(n);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + " " + h + m + s;
    }
    return "时间格式错误";
  }
  return "";
}

// 判断是否是移动端
export const isMobile = () => {
  return !!navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i);
};

// 去除文生图的多余参数
export const removeOptions = (str) => {
  const regexList = [
    /--ar\s\d+:\d+/g, // 匹配 --ar 3:4 或类似格式
    // /--s\s\d+/g, // 匹配 --s 750 或类似格式
    // /--v\s\d+/g, // 匹配 --v 5 或类似格式
  ];

  let result = str;
  regexList.forEach((regex) => {
    result = result.replace(regex, "");
  });

  return result.trim();
};

// 浅复制
export function copy(obj) {
  // 基本类型和函数直接返回
  if (!(obj instanceof Object) || typeof obj === "function") {
    return obj;
  }
  const newObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      newObj[p] = obj[p];
    }
  }
  return newObj;
}

// 生成随机数
export const randomNum = (minNum = 0, maxNum) => {
  return Math.floor(Math.random() * maxNum) + minNum;
};
