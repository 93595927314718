import axios from "@/common/http";

export default {
  state: () => ({
    artFaceGear: [], // 头像档位
    emojiGear: [], // emoji档位
    artFaceAndEmojiGear: [], // artFace 和 emoji档位
    payModal: false, // 支付弹窗
    payPackId: null,
    paySelectedStyle: 0,
    subscribeGear: [], // 会员订阅档位
  }),
  mutations: {
    setArtFaceGear: (state, list) => {
      state.artFaceGear = list;
    },
    setEmojiGear: (state, list) => {
      state.emojiGear = list;
    },
    setArtFaceAndEmojiGear: (state, list) => {
      state.artFaceAndEmojiGear = list;
    },
    setPayModal: (state, val) => {
      state.payModal = val;
    },
    setPayPackId: (state, val) => {
      state.payPackId = val;
    },
    setPaySelectedStyle: (state, val) => {
      state.paySelectedStyle = val;
    },
    setSubscribeGear: (state, list) => {
      state.subscribeGear = list;
    },
  },
  actions: {
    /**
     *
     *@desc 获取支付档位 - 会员订阅
     * 制作头像代码已删
     */
    async getProductList({ commit }) {
      const data = {
        product_type: 1,
        ai_works_type: 4,
        subscribe_type: 0,
        chn: "web",
      };
      const res = await axios.post("/v1/product/list", data);
      console.log(commit);
      if (res.status === "200") {
        commit("setSubscribeGear", res.data.list);
      }
      return res;
    },
    /**
     *@desc 创建订单
     */
    async reqOrderCreate(
      { commit, state },
      [product_id, pay_method, price_id]
    ) {
      let data = {
        price_id: price_id || "",
        product_id,
        number: 1,
        pay_method: pay_method || 6,
        pack_id: state.payPackId,
      };
      const res = await axios.post("/v1/order/create", data);
      console.log(commit);
      if (res.status === "200") {
        window.open(res.data.stripe_link_return.url, "_self");
      }
      return res;
    },
    /**
     *@desc 取消订阅
     */
    async cancelSubscribe({ commit, dispatch }) {
      const res = await axios.post("/v1/order/cancel/subscribe");
      console.log(commit);
      if (res.status === "200") {
        dispatch("reqGetUserInfo");
        var timerId = setTimeout(function () {
          dispatch("reqGetUserInfo");
          clearTimeout(timerId);
        }, 3000);
      }
      return res;
    },
  },
  getters: {},
};
