<template>
  <div class="parent" v-show="isLoading">
    <div class="loading">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LD",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.parent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  display: inline-block;
  text-align: center;
}

.spinner {
  display: inline-block;
  width: 48px;
  height: 48px;
  animation: spin 1.2s linear infinite;
  background: url("../../assets/images/iconsV2/icon_loading.png");
  background-size: 100% 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
