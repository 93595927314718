<template>
  <img
    class="ai-lazy-img"
    :data-src="src"
    src="../../assets/images/icons/img_loading.png"
    v-bind="config"
  />
</template>
<script>
export default {
  name: "ImgLazy",
  props: {
    // img src
    src: {
      type: String,
      default: "",
    },
    // img config
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {
    const lazyImages = document.querySelectorAll(".ai-lazy-img");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const src = entry.target.dataset.src;
          const img = new Image();
          img.onload = () => {
            entry.target.src = src;
            observer.unobserve(entry.target);
          };
          img.src = src;
        }
      });
    });
    lazyImages.forEach((image) => {
      observer.observe(image);
    });
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.ai-lazy-img {
  width: 100%;
  height: 100%;
}
</style>
