import axios from "@/common/http";

export default {
  state: () => ({
    styleList: [], // 风格列表
    imageGroupList: [], // 已生成的照片组列表
    selectedPackId: "", //选择的照片组
    selectedImageGroup: {}, //选择的已有照片组
    uploadFileList: [], // 生成头像过程中 上传的图片列表
    uploadUrlList: [], // 上传成功后的照片地址
    uploadUrlErrorList: [], // 上传失败后的照片地址
    uploaderStatus: false, // 上传中true 没有上传false
    showGender: false, // 选择性别的弹窗
    selectedGender: "", //选择的性别
    selectedStyle: [],
    needChange: false,
  }),
  mutations: {
    setStyleList: (state, list) => {
      state.styleList = list;
    },
    setImageGroupList: (state, list) => {
      state.imageGroupList = list;
    },
    addUploadFileList: (state, files) => {
      // 上传文件数最大为15张
      state.uploadFileList = state.uploadFileList.concat(files);
      if (state.uploadFileList.length > 15) {
        state.uploadFileList = state.uploadFileList.slice(0, 15);
      }
    },
    addUploadUrlList: (state, data) => {
      if (data["status"]) {
        // 上传文件数最大为15张
        if (state.uploadUrlList.length < 15) {
          state.uploadUrlList.push(data["url"]);
        }
      } else {
        state.uploadUrlErrorList.push(data["url"]);
      }
    },
    resetUploadUrlList: (state) => {
      state.uploadUrlList = [];
    },
    restUploadUrlErrorList: (state) => {
      const count = state.uploadUrlErrorList.length;
      state.uploadFileList.splice(-count, count);
      state.uploadUrlErrorList = [];
    },
    resetUploadFileList: (state) => {
      state.uploadFileList = [];
    },
    setUploadStatus: (state, value) => {
      state.uploaderStatus = value;
    },
    setSelectedGender: (state, value) => {
      state.selectedGender = value;
    },
    setShowGender: (state, value) => {
      state.showGender = value;
    },
    setNeedChange: (state, value) => {
      state.needChange = value;
    },
    setSelectedStyle: (state, value) => {
      state.selectedStyle = state.selectedStyle.concat(value);
      state.selectedStyle = Array.from(new Set(state.selectedStyle));
    },
    resetSelectedStyle: (state) => {
      state.selectedStyle = [];
    },
    spliceSelectedStyle: (state, value) => {
      let styles = state.selectedStyle;
      const index = styles.indexOf(value);
      if (index > -1) {
        styles.splice(index, 1);
        state.selectedStyle = styles;
      }
    },
    setSelectedPackId: (state, value) => {
      state.selectedPackId = value;
    },
    setSelectedImageGroup: (state, value) => {
      state.selectedImageGroup = value;
    },
  },
  actions: {
    /**
     *
     * @desc 风格列表
     * @param {*} type  -2 全部 -1 其他 0 女 1 男
     * @returns
     */
    async getStyleList({ commit }, type) {
      const res = await axios.post("/v1/aiemoji/style", { type: type });
      commit("setStyleList", res.data);
      return res;
    },
    /**
     *
     * @desc 文件上传 目前图片只能单张上传（有裁剪功能）
     * @param {*} file
     * @returns
     */
    async uploadFile({ commit }, file) {
      const res = await axios.post("/v1/upload/pic2", file);
      if (res.status === "200") {
        commit("addUploadUrlList", {
          url: res.data,
          status: true,
        });
      } else {
        for (var value of file.values()) {
          let URL = window.URL || window.webkitURL;
          let data = URL.createObjectURL(value);
          commit("addUploadUrlList", {
            url: data,
            status: false,
          });
        }
      }
      return res;
    },
    /**
     * @desc 统一文件上传 目前图片只能单张上传（没有裁剪功能）
     * @param {*} file
     * @returns
     */
    async publicUploadFile({ commit }, file) {
      console.log(commit);
      const res = await axios.post("/v1/upload/pic", file);
      return res;
    },
    /**
     * @desc 创建照片组 或者开始训练
     * @param data
     * {
     * pack_id:string
     * preset_id:[string],
     * pics:[string],
     * sex: 0,1,-1,
     * count: 100
     * }
     */
    async createPackOrTrail({ commit }, data) {
      const res = await axios.post("/v1/aiemoji/pack/create", data);
      console.info(commit);
      return res;
    },
    /**
     * @desc 获取照片组
     */
    async getPackList({ commit }) {
      const res = await axios.post("/v1/aiemoji/model");
      commit("setImageGroupList", res.data);
      return res;
    },
  },
  getters: {},
};
