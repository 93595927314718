// Desc: 邀请码绑定
import store from "@/store";
import router from "@/router";
import { LocalDataUtil } from "./util";
export default async () => {
  if (LocalDataUtil.getItem("registerode")) {
    const params = {
      register_code: LocalDataUtil.getItem("registerode"),
    };
    const res = await store.dispatch("postInvitefriendsAdd", params);
    if (res.status === "200") {
      let data = LocalDataUtil.getItem("aipacewebuserinfo");
      let userInfo = data ? JSON.parse(data) : {};
      userInfo["invited"] = 1;
      LocalDataUtil.setItem("aipacewebuserinfo", JSON.stringify(userInfo));
      router.replace("edit/community");
    } else {
      store.commit("setLoginStatus", "Invited");
      store.commit("setShowLogin", true);
    }
    LocalDataUtil.removeItem("registerode");
  }
};
