<template>
  <div class="page">
    <div class="header">
      <div class="page-content header-wrap">
        <div class="logo"></div>
        <div class="create-btn" @click="goEdit">{{ $t("createNow") }}</div>
      </div>
    </div>
    <div class="banner-wrap">
      <h1>{{ $t("igniteCreativity") }}</h1>
      <p>{{ $t("igniteCreativityDesc") }}</p>
      <a target="_blank" href="http://u3v.cn/5x79v9">{{ $t("betaBtn") }}</a>
    </div>
    <LoginDialog></LoginDialog>
    <div class="magic-banner">
      <!-- <MagicBanner
        placement="right"
        :title="$t('magicBanner1')"
        :desc="$t('magicBanner1Desc')"
        img="https://esx.esxscloud.com/liveglb/202306251725/content/resource_manage/md5_574d4e5a59befb32b12b698cbefa9393_.png"
      /> -->
      <MagicBanner
        :title="$t('magicBanner2')"
        :desc="$t('magicBanner2Desc')"
        img="https://esx.esxscloud.com/liveglb/202306251726/content/resource_manage/md5_e69c8d71434e8f8e1d92be1d92b019c3_.png"
      />
      <MagicBanner
        placement="right"
        :title="$t('magicBanner3')"
        :desc="$t('magicBanner3Desc')"
        img="https://esx.esxscloud.com/liveglb/202306251726/content/resource_manage/md5_e619e097dde4cec60a8781fa80f6a519_.png"
      />
      <MagicBanner
        :title="$t('magicBanner4')"
        :desc="$t('magicBanner4Desc')"
        img="https://esx.esxscloud.com/liveglb/202306251726/content/resource_manage/md5_9e1eb0c68b677a5b3a8507029c047392_.png"
      />
    </div>
    <div class="footer-wrap">
      <div class="footer page-content">
        <div class="footer-logo">AISpark</div>
        <ul>
          <li class="footer-title">{{ $t("aboutThePlatform") }}</li>
          <li>
            <a
              target="_blank"
              href="https://www.aispark.ai/protocol/privacy.html"
              >{{ $t("uploadPrivacy") }}</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.aispark.ai/protocol/terms.html"
              >{{ $t("uploadTerms") }}</a
            >
          </li>
        </ul>
        <ul>
          <li class="footer-title">{{ $t("help") }}</li>
          <li>{{ $t("helpContact") }}</li>
        </ul>
        <div class="logo"></div>
      </div>
    </div>
  </div>
</template>

<script>
import registerCode from "@/common/registerCode.js";
import LoginDialog from "@/components/Home/Login.vue";
import { isLogin } from "@/common/util";
import { getQueryByName, delUrlParam, LocalDataUtil } from "@/common/util";
import MagicBanner from "@/components/Home/MagicBanner.vue";

export default {
  name: "ObtainCodeView",
  data() {
    return {
      userInfo: {},
      whetherLogin: false,
    };
  },
  components: {
    LoginDialog,
    MagicBanner,
  },
  computed: {},
  watch: {
    "$store.state.showLogin": {
      immediate: true,
      handler() {
        // this.getUserInfo();
      },
    },
  },
  mounted() {
    this.checkLogin();
    this.getUserInfo("go");
  },
  methods: {
    getUserInfo(type) {
      let data = LocalDataUtil.getItem("aipacewebuserinfo");
      this.userInfo = data ? JSON.parse(data) : {};
      this.whetherLogin = isLogin();
      if (type === "go" && this.whetherLogin) {
        this.replace();
      }
    },
    replace() {
      if (this.userInfo["invited"] === 0) {
        if (LocalDataUtil.getItem("registerode")) {
          this.$store.commit("setShowLogin", false);
          registerCode();
        } else {
          this.$store.commit("setLoginStatus", "Invited");
          this.$store.commit("setShowLogin", true);
        }
      } else {
        this.$router.replace("edit/community");
      }
    },
    handleLogin() {
      this.$store.commit("setShowLogin", true);
    },
    goEdit() {
      this.getUserInfo();
      if (this.whetherLogin) {
        this.replace();
      } else {
        this.handleLogin();
      }
    },
    async checkLogin() {
      if (document.location.href.indexOf("code=") > -1) {
        const res = await this.$store.dispatch("getThirdLogonToken", {
          code: getQueryByName("code"),
          platform: new Number(getQueryByName("p")),
        });
        console.info(res);
        LocalDataUtil.setItem("aispacewebtoken", res.data.token);
        LocalDataUtil.setItem(
          "aipacewebuserinfo",
          JSON.stringify(res.data.user_info)
        );
        // this.$router.replace("edit/community");
        this.getUserInfo("go");
        // 删除登陆后的code
        delUrlParam(["code", "p", "state"]);
      }
      if (
        document.location.href.indexOf("resetkey=") > -1 &&
        document.location.href.indexOf("email=") > -1
      ) {
        // 重置密码
        this.$store.commit("setResetPassword", {
          resetkey: getQueryByName("resetkey"),
          email: getQueryByName("email"),
        });
        this.$store.commit("setLoginStatus", "ResetPassword");
        this.$store.commit("setShowLogin", true);
        delUrlParam(["resetkey", "email"]);
      }
      if (
        document.location.href.indexOf("key=") > -1 &&
        document.location.href.indexOf("userID=") > -1
      ) {
        // 激活账号
        const params = {
          key: getQueryByName("key"),
          userID: getQueryByName("userID"),
        };
        const res = await this.$store.dispatch("postActivate", params);
        if (res.status === "200") {
          this.$store.commit("setLoginStatus", "SignIn");
          this.$store.commit("setShowLogin", true);
          this.$toast({
            type: "success",
            message: this.$t("accountActivationSuccessful"),
            duration: 3,
          });
        } else {
          this.$toast({
            type: "waring",
            message: res.msg,
            duration: 3,
          });
        }
        delUrlParam(["key", "userID"]);
      }
      if (document.location.href.indexOf("registerode=") > -1) {
        let registerode = getQueryByName("registerode");
        LocalDataUtil.setItem("registerode", registerode);
        delUrlParam(["registerode"]);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: rgba(6, 8, 8, 1);
  .header {
    width: 100%;
    height: 94px;
    background: rgba(23, 53, 56, 1);
    .header-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
    .logo {
      width: 194px;
      height: 60px;
      background: url("../assets/images/iconsV2/logo.png") no-repeat;
      background-size: 100% 100%;
    }
    .create-btn {
      padding: 0 32px;
      height: 64px;
      background: linear-gradient(135deg, #80ffa1 0%, #3bf7de 100%);
      border-radius: 56px;
      font-size: 24px;
      font-family: GothamMedium;
      color: #060808;
      line-height: 64px;
      cursor: pointer;
    }
  }
  .banner-wrap {
    width: 100%;
    height: 1140px;
    background: url("../assets/images/resourcesV2/banner.png") no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    h1 {
      padding: 260px 0 50px 0;
      width: 100%;
      font-size: 110px;
      line-height: 108px;
      font-family: GothamBold;
      color: #f5e9d9;
      text-align: center;
    }
    p {
      text-align: center;
      height: 23px;
      font-size: 24px;
      line-height: 23px;
      margin-bottom: 83px;
      font-family: GothamBook, Gotham;
      font-weight: normal;
      color: #f5e9d9;
    }
    a {
      margin: 0 auto;
      width: 569px;
      display: block;
      height: 64px;
      font-size: 24px;
      font-family: GothamMedium;
      color: #060808;
      text-align: center;
      line-height: 64px;
      background: linear-gradient(135deg, #80ffa1 0%, #3bf7de 100%);
      border-radius: 52px;
    }
  }
  .magic-banner {
    margin-top: -392px;
  }
  .page-content {
    width: 1440px;
    margin: 0 auto;
  }
  .footer-wrap {
    background: rgba(31, 33, 33, 1);
    height: 180px;
    color: #f5e9d9;
    padding-top: 36px;
    .footer {
      display: flex;
      .footer-logo {
        width: 456px;
        font-size: 36px;
        font-family: GothamBold;
      }
      ul {
        width: 353px;
        .footer-title {
          font-size: 16px;
          font-family: GothamMedium;
          line-height: 23px;
          color: #f5e9d9;
        }
        li {
          margin-bottom: 8px;
          font-size: 14px;
          color: rgba(159, 153, 143, 1);
          font-family: GothamBook, Gotham;
          line-height: 17px;
        }
      }
    }
  }
}

/* 小于等于1440px时应用的样式 */
@media (max-width: 1440px) {
  .page-content {
    width: 1080px !important;
    box-sizing: border-box;
  }
  .header {
    height: 71px;
    .logo {
      width: 146px;
      height: 45px;
    }
    .create-btn {
      padding: 0 30px;
      height: 48px;
      border-radius: 28px;
      font-size: 42px;
      line-height: 48px;
    }
  }
  .banner-wrap {
    height: 855px;
    h1 {
      padding: 196px 0 38px 0;
      width: 100%;
      font-size: 83px;
      line-height: 81px;
    }
    p {
      height: 17px;
      font-size: 18px;
      line-height: 17px;
      margin-bottom: 62px;
    }
    a {
      width: 427px;
      height: 54px;
      font-size: 18px;
      line-height: 18px;
      border-radius: 39px;
    }
  }
  .magic-banner {
    margin-top: -310px;
  }
  .footer-wrap {
    height: 135px;
    padding-top: 27px;
    .footer {
      display: flex;
      .footer-logo {
        width: 342px;
        font-size: 27px;
      }
      ul {
        width: 265px;
        .footer-title {
          font-size: 12px;
          line-height: 17px;
          color: #f5e9d9;
        }
        li {
          margin-bottom: 6px;
          font-size: 11px;
          line-height: 13px;
        }
      }
    }
  }
}

/* 小于等于768px时应用的样式 */
.is_mobile {
  .page-content {
    padding: 0 12px;
    width: 100% !important;
  }
  .header {
    height: 74px;
    .logo {
      width: 118px;
      height: 36px;
    }
    .create-btn {
      padding: 0 16px;
      height: 40px;
      border-radius: 28px;
      font-size: 14px;
      line-height: 40px;
    }
  }
  .banner-wrap {
    padding: 0 20px;
    height: 226px;
    h1 {
      padding: 80px 0 24px 0;
      width: 100%;
      font-size: 29px;
      line-height: 30px;
    }
    p {
      height: 16px;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 50px;
    }
    a {
      width: 332px;
      height: 48px;
      font-size: 14px;
      line-height: 48px;
      border-radius: 30px;
    }
  }
  .magic-banner {
    margin-top: 120px;
  }

  .footer-wrap {
    height: auto;
    padding: 0;
    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .footer-logo {
        display: none;
      }
      ul {
        width: 265px;
        .footer-title {
          margin-top: 20px;
          font-size: 16px;
          line-height: 23px;
          color: #f5e9d9;
        }
        li {
          margin-bottom: 6px;
          font-size: 11px;
          line-height: 13px;
        }
      }
      .logo {
        display: block;
        width: 118px;
        height: 37px;
        margin: 24px auto;
        background: url("../assets/images/iconsV2/logo.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
