import axios from "@/common/http";

export default {
  state: () => ({
    homeRecommendList: [], // 首页推荐列表
    currentHomeDetailItem: {},
    homeNormalList: [], // 首页普遍列表
    stepsTip: false,
  }),
  mutations: {
    setHomeRecommonList: (state, value) => {
      state.homeRecommendList = value;
    },
    setCurrentHomeDetailItem: (state, value) => {
      state.currentHomeDetailItem = value;
    },
    setHomeNormalList: (state, value) => {
      state.homeNormalList = value;
    },
    setStepsTip: (state, value) => {
      state.stepsTip = value;
    },
  },
  actions: {
    /**
     *
     *@desc 获取emoji首页列表
     */
    async getEmojiHome({ commit }) {
      const res = await axios.post("/v1/discover/homepage");
      let recommondList;
      let normalList = [];
      const data = res.data;
      for (let i = 0; i < data.length; i++) {
        if (data[i].type == 1) {
          recommondList = data[i].list;
        }
        if (data[i].type == 2) {
          normalList.push(data[i]);
        }
      }
      commit("setHomeRecommonList", recommondList);
      commit("setHomeNormalList", normalList);
      return res;
    },

    async getRecommondEmojiDetail({ commit }, packId) {
      const res = await axios.post("/v1/discover/aidetail", {
        pack_id: packId,
      });
      console.log("res", res.data);
      commit("setCurrentHomeDetailItem", res.data);
    },

    async getNormalEmojiDetail({ commit }, packId) {
      const res = await axios.post("/v1/discover/emojidetail", {
        pack_id: packId,
      });
      commit("setCurrentHomeDetailItem", res.data);
    },

    // 创建emoji
    async postCreateEmoji({ commit }, data) {
      const res = await axios.post("/v1/aiemoji/emoji/create", data);
      console.info(commit);
      return res;
    },
  },
  getters: {},
};
