export default {
  // sidebar
  sidebarAiGeneration: "Al Image Generation",
  sidebarAiArtface: "AI ArtFace",
  sidebarAiEmoji: "AI Emoji",
  sidebarGenerateRecord: "Generate Record",
  sidebarSignIn: "Sign-in / Sign-up",
  sidebarSignout: "SignOut",
  sidebarNotifications: "Notifications",
  sidebarLiked: "Liked List",
  sidebarOther: "Other",
  sidebarUserTools: "User Tools",
  sidebarCommunityFeed: "Community Feed",
  sidebarStartHere: "Start Here",
  sidebarInviteFriends: "Invite Friends",

  // upload
  uploadTopic: "Magic Avatars",
  uploadTitle: "Upload photos and select styles to generate exclusive avatars.",
  uploadTips1: "Add 4-15 photos of the same person",
  uploadTips2: "Click here or drag and drop the photos to upload",
  uploadTips2Disabled: "You can continue to add after deleting pictures",
  uploadTips3: "By uploading photos you agree to our user",
  uploadTips5: "Add {num} photos to proceed",
  uploadTips7: "Upload limit reached, please select the avatar styles",
  uploadTerm: "term",
  createAvatar: "Generate Avatar",
  createAvatarStyle: "Continue with {num} style(s)",
  viewAll: "View All",
  goodExamples: "Good Examples",
  badExamples: "Bad Examples",
  photos: "Photos",
  selectGender: "Select your gender",
  female: "Female",
  male: "Male",
  uploading: "Importing photos...",
  goodExamplesBelow:
    "Close-up selfies, same person, adults, variety of backgrounds, facial expressions, head tilts and angles",
  badExamolesBelow:
    "Group shots, full-length, kids, covered faces, animals, monotonous pics, nudes",
  insufficientPhotos: "Insufficient Photos",
  insufficientPhotosTips:
    "{num1} photo(s) failed to meet the requirements, please add at least {num2} more.",
  success: "Success",
  fail: "Fail",
  inadequatePhotos: "Please add at least {num} more",
  //select Style
  selectStyle: "Select Style",
  selectStyle1: "Select up to 5 styles",
  selectStyleTips: "Please select 1-5 styles.",
  // edit header
  myAccount: "My Account",
  cancel: "Cancel",
  delete: "Delete",

  // home
  home: "Home",
  faq: "FAQ",
  download: "Download",
  goToWebApp: "Go to web app",
  createNow: "Create Now",
  homeSlogon: "Create different styles of yourself through AI",
  homeEmjioSlogon: "Create different styles of yourself with AI technology",
  igniteCreativity: "Ignite creativity",
  igniteCreativityDesc:
    "Let AI assist you and improve creativity efficiency, creating stunning works!",
  betaBtn: "🎉Apply for beta testing",
  helpContact: "Contact support@aispark.ai",
  aboutThePlatform: "About the platform",
  magicBanner1: "AI try-on",
  magicBanner1Desc:
    "AI automatic dressing, one model image applies to hundreds of products, upgrade your product images, saving shooting and time costs.",
  magicBanner2: "AI supermodel",
  magicBanner2Desc:
    "AI-generated models and backgrounds, upgrade your product images, saving shooting and time costs.",
  magicBanner3: "AI product image",
  magicBanner3Desc:
    "AI generates massive scene images, just a few clicks to generate e-commerce product images.",
  magicBanner4: "AI painting",
  magicBanner4Desc: "Create amazing original artwork in just a few minutes.",

  artFaceDesc:
    "🥳AI Avatar allows you to see different versions of yourself and get more personalized avatars.",
  emojiDesc:
    "🤪AI Emoji helps enrich and bring your avatars to life by adding various fun elements.",
  app: "APP",
  webApp: "Web App",
  appStore: "App Store",
  googlePlay: "Google Play",

  excellentTitle: "Excellent User Works",

  // common question
  subscribedTitle: "Manage Subscription",
  subscribedDesc: "Upgrade to unlock and unleash your artistic potential.",
  questionsTitle: "Frequently Asked Questions",
  questionSubTitle:
    "Can't find what you're looking for? Email us at 📮support@aispark.ai",
  question1: "How do credits work?",
  answer1:
    "Every 2 credits allow you to generate one image. The points required to generate the image depend on the parameters and tools you use. The 'Generate' button will show the credits required for each generation.",
  question2: "How can I get credits?",
  answer2:
    "You can: 1.Subscribe to a plan. 2.You can receive 200 credits for free every month.",
  question3: "Can I cancel my subscription plan?",
  answer3:
    "Yes, you can cancel at any time. Your plan will remain active until the renewal date, after which it will change to a Free plan.",
  question4: "Do credits expire?",
  answer4:
    "Plan credits reset on each subscription renewal date. If you cancel your subscription, plan credits will expire on the renewal date.",
  question5: "Can I get a refund?",
  answer5:
    "Unfortunately, we cannot offer refunds due to the nature of the service.",
  // footer
  help: "Help",
  aboutPlatform: "About the platform",
  protocolPolicy: "Privacy Policy",
  protocolTerms: "User Agreement",
  contact: "Contact",
  // homepage 中间五张彩色卡片切换swiper;
  homeCircleTitle1: "Create content for TikTok,Instagram...",
  homeCircleTitle2: "Create a Professional Profile Avatar",
  homeCircleTitle3: "Find your love with stunning selfies",
  homeCircleTitle4: "Create personalized emojis",
  homeCircleTitle5: "Play and win like a superhero",

  // login
  loginTitle: "Login",
  confirmChange: "Confirm Change",
  googleLogin: "Login with Google",
  fbLogin: "Login with Facebook",
  appleLogin: "Login with Apple",
  loginTips1: "By signing up, you agree to the",
  loginTips2: "Terms of Service",
  loginTips3: "and",
  loginTips4: "Privacy Policy.",
  switchAccount: "Switch to another account",

  // recordList
  recordListTitle: "History Works",
  recordListTitleDesc:
    "Every time Al generates unique avatars, you will never have the same results!",
  recordImgNum: "images", //xx张
  recordGenerate: "Generating", //正在创建
  aiTypes0: "AI Avatars ",
  aiTypes1: "AI Emojis ",
  // recordAbout: "About", //预计还需
  // recordMinLeft: "min left",
  recordMinLeft: "About {num} min left",
  createBeatufy: "Creating beauty requires time and care...",
  recordCheckDetail: "View Details",
  btnStatusDesc0: "Refresh",
  btnStatusDesc1: "Create AI Emoji",
  btnStatusDesc2: "Regenerate",
  btnStatusDesc100: "Pay Now",
  payNum: "Purchase for {num}",
  noDataRecords: "No records yet",
  //edit/emoji
  editEmojiDesc:
    "Create first AI Avatars and then AI Emojis that bring your avatars to life.",
  createAIEmoji: "Create AI Emoji",
  quantity: "Quantity",
  downloads: "Downloads",
  downloadAll: "Download All",
  selectAll: "Select All",
  deselectAll: "Deselect All",
  selected: "Continue with {num} Avatar(s)",
  works: "works",
  stepCreate: "Step 1: Create AI Avatar",
  step: "Step {num}",
  stepTips: "AI Avatars will be created first and then the AI Emojis",
  gotIt: "Got it",
  //homepage cardCircle
  cardArtStylesTopTitle: "50+ Realistic and artistic style",
  cardArtStylesBelowTitle: "suitable for any scene",
  uploadTerms: "terms",
  uploadTips4: "and",
  uploadPrivacy: "privacy policy",
  // Tabs
  Hot: "Hot",
  New: "New",
  // Generation page
  addNegativePrompt: "Add Negative Prompt",
  generation: "Generation",
  generationHistory: "Generation history",
  deleteAll: "Delete All",
  generateAgain: "Generate Again",
  numberOfImages: "Number of Images",
  imageDimensions: "Image Dimensions",
  imageToImage: "Image to Image",
  advanced: "Advanced",
  created: "Created",
  close: "Close",
  open: "Open",
  width: "Width",
  height: "Height",
  steps: "Steps",
  scale: "Guidance Scale",
  faces: "Face Fixing",
  uploadTips6: "Add {num} photos",
  uploadTips8: "Upload or drag and drop an image to use as input",
  uploadTips9: "Privacy not visible",
  uploadTips10: "Original reference image from the author",
  uploadTips11: "Drop an image to use as input",
  modelName1: "Midjourney Style",
  modelName2: "Portrait 1.0",
  modelName3: "Design Materials 1.0",
  modelName4: "Interior Design 1.0",
  generationErrorTips01: "Invalid parameter",
  generationErrorTips02:
    "Please adjust the prompt words and try again, or please try again later.",
  delModalTitle: "Are you sure you want to deletethis generation?",
  delModalDesc: "If you confirm the generation will be impossible torecover.",
  inputPrompt: "Type a prompt",
  inputTipsPrompt: "The prompt word is invalid. Please input again.",
  inputNegativePrompt:
    "Type what you don't want to see in the image(a negative prompt)",
  sameStyle: "Same Style",
  ForwardPromptDetails: "Forward prompt details:",
  ReversePromptDetails: "Reverse prompt details:",
  publishWork: "Publish Work",
  release: "Release",
  title: "Title",
  titlePlaceholder: "Please enter the title of the work",
  copySuccess: "Copy Success",
  wordsError:
    "Sorry, the information you entered contains sensitive words: {sum}, please re-enter.",
  wordsLimitation: "Generating, try again later.",
  iw: "Image weight",
  s: "Stylize",
  seed: "Seed",
  sampler: "Sampler",
  iwTips:
    "The generated result is compared to the reference image and description text for similarity.",
  sTips:
    "The smaller the numerical value, the more minimalistic and flat it is, and the larger the numerical value, the more complex it is.",
  samplerTips:
    "Schedulers manage the process of image generation. DPM++ schedulers bring better image quality. Euler and DDIM schedulers generate images faster.",
  seedTips:
    "Using a fixed random seed to maximize the degree of restoration when generating images next time.",
  stepsTips: "Higher steps - higher quality, but slower",
  scaleTips:
    "The higher the value, the more the outcome sticks to the text prompt.",
  // 登录注册
  signUp: "Sign Up",
  email: "Email",
  placeholderEmail: "Email address",
  password: "Password",
  confirmPassword: "Confirm Password",
  forgotPassword: "Forgot Password",
  resetTitle: "Reset Password",
  changeTitle: "Change your password.",
  validatePassword: "6-18 characters, numbers and letters",
  validatePasswordConfirm: "Passwords do not match. Please check and try again",
  revisePassword:
    "The link has been sent to {num}. Please click the link in your email to reset the password~",
  registerEmail:
    "The login link has been sent to {num}. Go activate your account now~",
  emailUsed: "This email has been linked to another account",
  emailNotActive:
    "Please activate your email by clicking on the link in your inbox.",
  IncorrectEmailPassword: "Incorrect email or password",
  accountActivationSuccessful: "Account activation successful",
  emailNotRegistered: "Email not registered",
  loginSuccess: "Login successful",
  // 订阅
  subscribe: "Subscribe to",
  subscribed: "Subscribed",
  PayYearly: "Pay Yearly (20% OFF)",
  PayMonthly: "Pay Monthly",
  or: "Or",
  currently: "You are currently",
  freeVersion: "Free version",
  basicVersion: "Basic version",
  foreverFree: "Forever free",
  billedMonthly: "Billed monthly",
  billedYearly: "Billed yearly",
  monthly: "Monthly",
  yearly: "Yearly",
  support1: "200 credits /mo",
  support2: "1600 credits /mo",
  support22: "400 credits /mo",
  support3: "Low priority generation",
  support4: "High priority generation",
  support5: "Exclusive membership identification",
  support6: "Experience new features in advance",
  support7: "Access to all features",
  support8: "Queue up to 4 images",
  support9: "Queue up to 20 images",
  manageSubscription: "Manage",
  cancelPlan: "Cancel Plan",
  cancelPlanTips: "We're sorry to see you go!",
  canceled: "Plan canceled",
  generateCreadits: "Generate (This will use {num} credits)",
  upgrade: "Upgrade",
  // 提示词分类
  style: "Style",
  art: "Art",
  view: "View",
  light: "Light",
  material: "Material",
  render: "Render",
  quality: "Quality",
  template: "Template",
  // 提示词多语言
  randomPrompt: "Random Prompt",
  featuredModels: "Featured Models",
  promptStudio: "Prompt Studio",
  prompt: "Prompt",
  promptPlaceholder: "Choose a prompt word or template",
  promptTips: "“+” is for emphasis, “-”is for de-emphasis.",
  pictureSize: "Picture Size",
  placeholderTxt: "Please enter the content",
  invitationCode: "Invitation Code",
  confirm: "Confirm",
  placeholderCode: "Please enter invitation code",
  moreSettings: "More settings",
  // invite friends
  myInvitationCode: "My invitation code",
  copyInvitationCode: "Copy invitation link",
  invitationRecord: "Invitation record",
  nickname: "Nickname",
  registrationTime: "Registration time",
  invitedNum: "Number of invited people",
};
