<template>
  <div class="CustToast" v-if="showToast">
    <div class="icon">
      <div v-if="type === 'success'" class="icon-success"></div>
      <div v-if="type === 'info'" class="icon-info"></div>
      <div v-if="type === 'warning'" class="icon-warning"></div>
      <div v-if="type === 'error'" class="icon-error"></div>
    </div>
    <div class="toast-message">{{ message }}</div>
    <div @click="handleClose" class="toast-close"></div>
  </div>
</template>

<script>
export default {
  /**
   * params: showToast Boolean 是否激活toast 默认 false
   * params: type String       toast提示类型 共normal success，fail，warning 四个选项 默认normal
   * params: message String    toast消息
   * params: duration Number      toast显示时间 默认 2ms
   * */
  name: "CustToast",
  data() {
    return {
      showToast: true,
      type: "right",
      message: "消息提示",
      duration: 2,
      closed: false, // 用来判断消息框是否关闭
      timer: null,
    };
  },
  watch: {
    closed(val) {
      if (val) {
        this.showToast = false;
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.destroyElement();
      }
    },
  },
  computed: {},
  methods: {
    handleClose() {
      this.closed = true;
    },
    //完全销毁实例
    destroyElement() {
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    },
    //计时器，根据 duration 去修改组件的开关状态
    startTimer() {
      console.log(this.duration, "this.duration");
      if (this.duration) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.closed = true;
            clearTimeout(this.timer);
          }
        }, this.duration * 1000);
      }
    },
  },
  mounted() {
    this.startTimer();
  },
};
</script>

<style scoped lang="less">
.CustToast {
  position: fixed;
  left: 83%;
  top: 10%;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  animation: show-toast 0.2s;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 357px;
  height: 78px;
  z-index: 999;
  border-radius: 10px;
  font-size: 0;
}
.toast-close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  background: url("../../assets/images/iconsV2/icon_modal_close.png") no-repeat;
  background-size: 12px 12px;
  background-position: center;
  cursor: pointer;
}
@keyframes show-toast {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.toast-message {
  width: 240px;
  font-size: 11px;
  font-family: PingFangTC-Medium, PingFangTC;
  font-weight: 500;
  color: rgba(106, 107, 107, 1);
  line-height: 15px;
}
.right {
  //   color: #67c23a;
  //   background: rgb(225, 243, 216);
}

// .warning {
//   color: #e6a23c;
//   background: rgb(250, 236, 216);
// }

.error {
  //   color: #f56c6c;
  //   background: rgb(253, 226, 226);
}

.icon {
  width: 18px;
  height: 18px;
  margin: 0px 14px;
  div {
    width: 18px;
    height: 18px;
  }
  .icon-success {
    background: url("../../assets/images/iconsV2/icon_success.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon-info {
    background: url("../../assets/images/iconsV2/icon_info.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon-warning {
    background: url("../../assets/images/iconsV2/icon_warning.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon-error {
    background: url("../../assets/images/iconsV2/icon_error.png") no-repeat;
    background-size: 100% 100%;
  }
}
.is_mobile {
  .CustToast {
    top: 8%;
    left: auto;
    right: 20px;
    transform: translate(0%, 0%);
    height: auto;
    min-height: 54px;
    width: 80%;
  }
  .toast-message {
    width: 70%;
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
