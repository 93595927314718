<template>
  <span>
    <AiInput
      :label="$t('password')"
      v-model="password"
      type="password"
      :placeholder="$t('password')"
      prop="password"
      :error="errorObj['password']"
      :maxlength="18"
      @onClearError="onClearError"
    />
    <AiInput
      :label="$t('confirmPassword')"
      v-model="confirmPassword"
      type="password"
      :maxlength="18"
      :placeholder="$t('confirmPassword')"
      prop="confirmPassword"
      :error="errorObj['confirmPassword']"
      @onClearError="onClearError"
    />
    <div class="login-btn-wrap">
      <div class="login-btn" @click="!loginLoading ? handleLogin() : ''">
        {{ $t("confirmChange") }}
      </div>
    </div>
  </span>
</template>
<script>
import AiInput from "@/components/Input/Index.vue";
import { isPassword } from "@/utils/reg_exp.js";
export default {
  name: "ResetPassword",
  components: {
    AiInput,
  },
  props: {},
  watch: {},
  data() {
    return {
      password: "",
      confirmPassword: "",
      errorObj: {},
    };
  },
  computed: {
    loginLoading() {
      return this.$store.state.loginLoading;
    },
  },
  mounted() {},
  methods: {
    onClearError(val) {
      this.errorObj[val] = "";
    },
    async handleLogin() {
      this.errorObj = {};
      if (!isPassword(this.password)) {
        this.$set(this.errorObj, "password", this.$t("validatePassword"));
      } else {
        this.$set(this.errorObj, "password", "");
      }
      if (this.password !== this.confirmPassword) {
        this.$set(
          this.errorObj,
          "confirmPassword",
          this.$t("validatePasswordConfirm")
        );
      } else {
        this.$set(this.errorObj, "confirmPassword", "");
      }
      if (this.errorObj["password"] || this.errorObj["confirmPassword"]) {
        return;
      }
      // 修改密码
      const params = {
        password: this.password,
        email: this.$store.state.resetPassword.email,
        resetkey: this.$store.state.resetPassword.resetkey,
      };
      const res = await this.$store.dispatch("postUserResetPassword", params);
      if (res.status === "200") {
        if (res.code === "12025" || res.code === "12020") {
          this.$toast({
            type: "warning",
            message: this.$t("emailNotRegistered"),
            duration: 5,
          });
        } else {
          this.$toast({
            type: "success",
            message: this.$t("success"),
            duration: 5,
          });
          this.$store.commit("setLoginStatus", "SignIn");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login-btn-wrap {
  padding-top: 7px;
  .login-btn {
    cursor: pointer;
    width: 100%;
    height: 39px;
    background: #111111;
    border-radius: 71px;
    font-size: 12px;
    font-family: PingFangTC-Medium, PingFangTC;
    font-weight: 500;
    color: #fff;
    text-align: center;
    line-height: 39px;
  }
}
</style>
