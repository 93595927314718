<template>
  <!-- 
    input 使用方法
    todo：后续可改造

        <AiInput
          label="卡上的姓名"
          v-model="name"
          placeholder="输入您的姓名"
          prop="name"
        />

   -->
  <div class="input-wrap">
    <label v-show="label" :for="prop">{{ label }}</label>
    <input
      :name="prop"
      :type="inputType"
      :placeholder="placeholder"
      :value="value"
      :class="{ 'is-invalid': error }"
      @input="updateValue"
      @blur="updateError"
    />
    <div
      :class="inputType === 'password' ? 'icon-show' : 'icon-hide'"
      v-if="type === 'password'"
      @click="handlePassword"
    />
    <div class="invalid-feedback">
      <span v-if="error">
        {{ error }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "AiInput",
  props: {
    prop: { type: String, required: true },
    label: { type: String, default: "" },
    type: { type: String, default: "text" },
    placeholder: { type: String, default: "" },
    value: { type: String, default: "" },
    error: { type: String, default: "" },
    maxlength: { type: Number, default: 100 },
  },
  model: {
    prop: "value",
    event: "update:value",
  },
  watch: {
    type: {
      handler(val) {
        this.inputType = val;
      },
      immediate: true,
    },
    error: {
      handler(val) {
        this.inputError = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      inputType: "",
      inputError: "",
    };
  },
  mounted() {},
  computed: {},
  methods: {
    handlePassword() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    },
    updateValue(e) {
      if (this.maxlength && e.target.value.length > this.maxlength) {
        e.target.value = e.target.value.slice(0, this.maxlength);
        this.$emit("update:value", e.target.value);
      } else {
        this.$emit("update:value", e.target.value);
      }
    },
    updateError() {
      this.$emit("onClearError", this.prop);
    },
  },
};
</script>

<style lang="less" scoped>
.input-wrap {
  width: 100%;
  position: relative;
  .icon-show {
    position: absolute;
    right: 14px;
    top: 30px;
    width: 18px;
    height: 18px;
    background-image: url("../../assets/images/iconsV2/icon_hide.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
  .icon-hide {
    position: absolute;
    right: 14px;
    top: 30px;
    width: 18px;
    height: 18px;
    background-image: url("../../assets/images/iconsV2/icon_show.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
}
label {
  display: block;
  margin: 7px 0 5px 0;
  height: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 15px;
  font-size: 11px;
  color: #060808;
  line-height: 15px;
}
input {
  font-weight: 500;
  color: #060808;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 5px;
  border: 2px solid #e7e7e7;
  background-color: #fff;
  background-clip: padding-box;
  caret-color: "5564fe";
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input:focus {
  outline: none;
  border-color: #060808;
  // box-shadow: 0 0 0 1.5px rgba(6, 8, 8, 0.25);
}
input.is-invalid {
  border-color: rgba(229, 72, 99, 1);
}
input::placeholder {
  color: #a9a9a9;
}
.invalid-feedback {
  height: 14px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(229, 72, 99, 1);
  line-height: 14px;
  margin: 3px 0 10px 0;
}
</style>
