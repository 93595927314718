<template>
  <div class="magic-banner-wrap">
    <div :class="'magic-banner ' + placement">
      <div class="magic-text">
        <div class="title1">{{ title }}</div>
        <div class="title2">
          {{ desc }}
        </div>
      </div>
      <div class="magic-img">
        <img :src="img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MagicBanner",
  props: {
    placement: {
      type: String,
      default: "left",
    },
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped lang="less">
.magic-banner-wrap {
  margin-bottom: 70px;
}

.magic-banner {
  width: 1440px;
  margin: 0 auto;
  display: flex;
  .magic-text {
    color: #fff;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .title1 {
      font-size: 60px;
      font-family: GothamBold;
      color: #f5e9d9;
      line-height: 72px;
    }
    .title2 {
      font-size: 24px;
      font-family: Gotham;
      font-weight: normal;
      color: #f5e9d9;
      line-height: 28px;
    }
  }
  .magic-img {
    width: 516px;
    height: 516px;
    margin-left: 50px;
    flex-shrink: 0;
    border-radius: 60px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
    }
  }
  &.right {
    flex-direction: row-reverse;
    .magic-img {
      margin-left: 0;
      margin-right: 50px;
    }
  }
}

/* 小于等于1440px时应用的样式 */
@media (max-width: 1440px) {
  .magic-banner {
    width: 1080px;
    .magic-img {
      width: 387px;
      height: 387px;
    }
  }
}

.is_mobile {
  .magic-banner-wrap {
    margin-bottom: 35px;
  }
  .magic-banner {
    width: 100%;
    display: block;
    padding: 0 12px;
    .magic-img {
      border-radius: 20px;
      width: 100%;
      height: auto;
      margin-left: 0;
      margin-right: 0;
    }
    .magic-text {
      .title1 {
        text-align: center;
        font-size: 24px;
        font-family: GothamBold;
        color: #f5e9d9;
        line-height: 28px;
        margin-bottom: 4px;
      }
      .title2 {
        text-align: center;
        font-size: 14px;
        font-family: Gotham;
        font-weight: normal;
        color: #f5e9d9;
        line-height: 16px;
        padding: 0 20px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
