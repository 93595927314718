<template>
  <div :class="'ai-space-modal-wrap ' + modalClass">
    <transition name="modal-fade">
      <div class="modal-container" v-show="visible">
        <div class="modal-close" v-if="!closeHide" @click="close">
          <div class="modal-close-icon" />
        </div>
        <slot name="header" v-if="!headerHide">
          <div class="modal-header">{{ title }}</div>
        </slot>
        <div class="modal-body">
          <slot></slot>
        </div>
        <slot name="footer" v-if="!footerHide">
          <div class="modal-footer">插槽底部</div>
        </slot>
      </div>
    </transition>
    <div class="mask" v-show="visible" @click="!closeHide ? close() : ''"></div>
  </div>
</template>
<script>
export default {
  name: "AiSpaceModal",
  components: {},
  model: {
    prop: "visible",
    event: "update:visible",
  },
  props: {
    modalClass: {
      type: String,
      default: "",
    },
    // modal status
    visible: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    // modal width
    width: {
      type: Number,
      default: 364,
    },
    // 弹窗标题
    title: {
      type: String,
      default: "Modal",
    },
    // 不显示底部
    footerHide: {
      type: Boolean,
      default: false,
    },
    // 不显示头部
    headerHide: {
      type: Boolean,
      default: false,
    },
    // 没有关闭功能
    closeHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.$emit("update:visible", false);
      this.$emit("onChandeModal", false);
    },
  },
};
</script>

<style lang="less" scoped>
.ai-space-modal-wrap {
  margin: 0;
  .mask {
    z-index: 998;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .modal-container {
    z-index: 999;
    background-color: #fff;
    min-width: 324px;
    min-height: 208px;
    border: none;
    border-radius: 10px;
    transition: 0.6s;
    position: fixed;
    top: 160px;
    left: 50%;
    color: #000000;
    transform: translate(-50%, 0%);
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .modal-close {
    position: absolute;
    top: 6px;
    right: 8px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-close-icon {
      width: 27px;
      height: 27px;
      background: #ccc;
      background: url("../../assets/images/iconsV2/icon_close.png") no-repeat
        center center fixed;
      background-size: 100% 100%;
    }
  }
  .modal-header {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    font-family: Helvetica;
    color: #000000;
  }
  .modal-body {
    min-height: 88px;
    width: 100%;
  }
  // .modal-footer {}
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all 0.5s ease;
  transform: translateY(0);
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
  transform: translateY(50%);
}

.is_mobile {
  .ai-space-modal-wrap .modal-body[data-v-2a9bd8d0] {
    min-height: 58px;
  }
}

.mobile-modal {
  .modal-container {
    width: 100%;
    margin: 20px;
    top: 70px;
  }
  .mask {
    background-color: #000;
  }
}
</style>
