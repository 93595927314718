/**
-------------- 预览照片 -----------
 
一、组件的形式使用

    import aiViewer from "@/components/ImagesViewer/ImagesViewer.vue";
    <aiViewer :images="imgs"></aiViewer>
 
二、api的形式使用
 
  this.$imagesViewer({
    imagesList: this.imgs,
    options: {
      initialViewIndex: 0,
    },
  });

 **/
import Vue from "vue";
import ImageViewer from "./ImagesViewer.vue";

const ImageViewerConstructor = Vue.extend(ImageViewer);
let instance;
const install = () => {
  Vue.prototype.$imagesViewer = function (options) {
    if (instance) {
      instance.$destroy();
    }
    const mergedOptions = Object.assign({}, options);
    instance = new ImageViewerConstructor({
      data: mergedOptions,
    });

    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.show(options);
  };
};

export default install;
