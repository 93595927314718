<template>
  <!-- 
    预览照片   使用方法详见index.js
   -->
  <span>
    <div class="image-viewer-wrap" v-show="pageShow">
      <transition name="viewer-fade">
        <div class="viewer-container" @click.stop="close" :key="currentIndex">
          <div class="modal-close" @click="close">
            <div class="modal-close-icon" />
          </div>
          <div>
            <div
              class="image-viewer-left"
              v-show="!configs.toolbarHide"
              @click="handlePrev"
            >
              <div class="image-viewer-left-icon" />
            </div>
          </div>
          <div ref="viewerContainer" class="image-viewer-box" @click.stop="">
            <div class="img-box">
              <img v-if="currentImage" :src="currentImage" />
            </div>
            <div class="img-btn">
              <div
                v-show="!configs.downloadHide"
                @click="handleDownload"
                class="btn-download"
              />
              <div class="btn-post" @click="handleClickBtn">
                {{ $t("publishWork") }}
              </div>
            </div>
            <!-- <div class="img-btn-close" @click="close" /> -->
          </div>
          <div>
            <div
              class="image-viewer-right"
              v-show="!configs.toolbarHide"
              @click="handleNext"
            >
              <div class="image-viewer-right-icon" />
            </div>
          </div>
        </div>
      </transition>
      <div class="mask"></div>
    </div>
  </span>
</template>
<script>
import { downloadImage } from "@/utils/download";
export default {
  name: "ImagesViewer",
  props: {
    // 初始下标
    initialViewIndex: {
      type: Number,
      default: 0,
    },
    // 预览数组
    images: {
      type: Array,
      default: () => [],
    },
    // url 是哪个字段
    url: {
      type: String,
      default: "",
    },
    // 不显示操作栏
    toolbarHide: {
      type: Boolean,
      default: false,
    },
    // 不显示下载
    downloadHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageShow: true,
      currentIndex: 0,
      imagesList: [],
      // 配置
      options: {},
      configs: {
        initialViewIndex: 0,
        toolbarHide: false,
        downloadHide: false,
        url: "",
      },
    };
  },
  watch: {
    images: {
      immediate: true,
      handler(val) {
        this.imagesList = val;
      },
    },
    initialViewIndex: {
      immediate: true,
      handler(val) {
        this.configs["initialViewIndex"] = val;
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.initConfigs();
      },
    },
    currentImage: {
      immediate: true,
      handler() {
        this.handleWindowResize();
      },
    },
  },
  mounted() {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);
  },
  beforeDestroy() {
    window.addEventListener("resize", this.handleWindowResize);
  },
  computed: {
    currentImage() {
      let url = this.configs["url"] || this.url;
      return url
        ? this.imagesList[this.currentIndex][url]
        : this.imagesList[this.currentIndex];
    },
  },
  methods: {
    handleWindowResize() {
      this.$nextTick(() => {
        const viewerContainer =
          this.$refs.viewerContainer && this.$refs.viewerContainer.offsetHeight;
        const isViewportLessThan =
          viewerContainer && viewerContainer > window.innerHeight;
        if (viewerContainer && isViewportLessThan) {
          const scale = window.innerHeight / viewerContainer;
          this.$refs.viewerContainer.style.transform = `scale(${scale})`;
        } else if (viewerContainer) {
          this.$refs.viewerContainer.style.transform = "scale(1)";
        }
      });
    },
    // 预览图片的特殊处理
    handleClickBtn() {
      this.$emit("onClickBtn", this.currentIndex);
    },
    close() {
      this.$emit("onImageViewerShow", false);
      // this.pageShow = false;
    },
    show(data) {
      // this.pageShow = true;
      this.initConfigs(data);
    },
    initConfigs(data) {
      let options = (data && data["options"]) || this.options;
      this.configs = Object.assign(
        {
          initialViewIndex: this.initialViewIndex,
          url: this.url,
          toolbarHide: this.toolbarHide,
          downloadHide: this.downloadHide,
        },
        options
      );
      this.currentIndex = parseInt(this.configs["initialViewIndex"]) || 0;
    },
    handleDownload() {
      const link = this.currentImage;
      downloadImage(link);
    },
    handlePrev() {
      this.$nextTick(() => {
        this.currentIndex -= 1;
        if (this.currentIndex < 0) {
          this.currentIndex = this.imagesList.length - 1;
        }
      });
    },
    handleNext() {
      this.$nextTick(() => {
        this.currentIndex += 1;
        if (this.currentIndex >= this.imagesList.length) {
          this.currentIndex = 0;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.image-viewer-wrap {
  cursor: zoom-out;
  .mask {
    z-index: 300;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .viewer-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 301;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .image-viewer-box {
      padding: 20px 0;
      width: 384px;
      min-height: 384px;
      position: relative;
      .img-box {
        cursor: default;
        width: 384px;
        img {
          display: block;
          width: 100%;
        }
      }
      .img-btn {
        width: 100%;
        height: 45px;
        margin-top: 27px;
        display: flex;
        .btn-download {
          min-width: 45px;
          height: 45px;
          cursor: pointer;
          background: url("../../assets/images/iconsV2/icon_view_download.png");
          background-size: 100% 100%;
          margin-right: 9px;
        }
        .btn-post {
          width: 100%;
          height: 45px;
          line-height: 45px;
          text-align: center;
          cursor: pointer;
          background: linear-gradient(135deg, #80ffa1 0%, #3bf7de 100%);
          border-radius: 30px;
          font-size: 14px;
          font-family: GothamMedium;
          color: #060808;
          font-weight: bold;
        }
      }
      .img-btn-close {
        width: 22px;
        height: 22px;
        cursor: pointer;
        position: absolute;
        top: -40px;
        right: 0;
        background: url("../../assets/images/icons/icon_close_44.png");
        background-size: 100% 100%;
      }
    }
    .image-viewer-left {
      cursor: pointer;
      width: 138px;
      margin-top: -72px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .image-viewer-left-icon {
        width: 45px;
        height: 45px;
        background: url("../../assets/images/iconsV2/icon_click_left.png");
        background-size: 100% 100%;
      }
    }
    .image-viewer-right {
      cursor: pointer;
      width: 138px;
      margin-top: -72px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .image-viewer-right-icon {
        width: 45px;
        height: 45px;
        background: url("../../assets/images/iconsV2/icon_click_right.png");
        background-size: 100% 100%;
      }
    }
  }
}
.viewer-fade-enter-active,
.viewer-fade-leave-active {
  transition: opacity 0.9s;
}

.viewer-fade-enter,
.viewer-fade-leave-to {
  opacity: 0;
}

.modal-close {
  position: absolute;
  top: 18px;
  right: 18px;
  width: 27px;
  height: 27px;
  cursor: pointer;
  align-items: center;
  display: none;
  justify-content: center;
  .modal-close-icon {
    width: 27px;
    height: 27px;
    background: #ccc;
    background: url("../../assets/images/iconsV2/icon_round_close.png");
    background-size: 100%;
  }
}

.is_mobile {
  .image-viewer-wrap .viewer-container {
    // overflow-y: auto;
    // margin: 68px 0;
    overflow-y: auto;
    display: block;
  }
  .record-wrap {
    display: block;
  }
  .image-viewer-wrap .viewer-container .image-viewer-left,
  .image-viewer-wrap .viewer-container .image-viewer-right {
    display: none;
  }
  .image-viewer-wrap .viewer-container .image-viewer-box {
    padding: 0;
    width: 90%;
    min-height: auto;
    transform: scale(1) !important;
    margin: 68px auto;
  }
  .image-viewer-wrap .viewer-container .image-viewer-box .img-box {
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
  }
  .image-viewer-wrap .mask {
    background-color: rgba(0, 0, 0);
  }

  .modal-close {
    display: flex;
  }
}
</style>
