<template>
  <span>
    <AiInput
      :label="$t('email')"
      v-model="email"
      :placeholder="$t('placeholderEmail')"
      prop="email"
      :error="errorObj['email']"
      :maxlength="50"
      @onClearError="onClearError"
    />
    <AiInput
      :label="$t('password')"
      v-model="password"
      type="password"
      :placeholder="$t('password')"
      prop="password"
      :error="errorObj['password']"
      :maxlength="18"
      @onClearError="onClearError"
    />
    <AiInput
      :label="$t('confirmPassword')"
      v-model="confirmPassword"
      type="password"
      :placeholder="$t('confirmPassword')"
      prop="confirmPassword"
      :error="errorObj['confirmPassword']"
      :maxlength="18"
      @onClearError="onClearError"
    />
    <div class="login-btn-wrap">
      <div class="login-btn" @click="!loginLoading ? handleLogin() : ''">
        {{ $t("signUp") }}
      </div>
    </div>
  </span>
</template>
<script>
import AiInput from "@/components/Input/Index.vue";
import { isEmail, isPassword } from "@/utils/reg_exp.js";
export default {
  name: "SignUp",
  components: {
    AiInput,
  },
  props: {},
  watch: {},
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      errorObj: {},
    };
  },
  mounted() {},
  computed: {
    loginLoading() {
      return this.$store.state.loginLoading;
    },
  },
  methods: {
    onClearError(val) {
      this.errorObj[val] = "";
    },
    async handleLogin() {
      this.errorObj = {};
      if (!isEmail(this.email)) {
        this.$set(this.errorObj, "email", this.$t("placeholderEmail"));
      } else {
        this.$set(this.errorObj, "email", "");
      }
      if (!isPassword(this.password)) {
        this.$set(this.errorObj, "password", this.$t("validatePassword"));
      } else {
        this.$set(this.errorObj, "password", "");
      }
      if (this.password !== this.confirmPassword) {
        this.$set(
          this.errorObj,
          "confirmPassword",
          this.$t("validatePasswordConfirm")
        );
      } else {
        this.$set(this.errorObj, "confirmPassword", "");
      }
      if (
        this.errorObj["email"] ||
        this.errorObj["password"] ||
        this.errorObj["confirmPassword"]
      ) {
        return;
      }
      // 直接调用注册接口
      const params = {
        email: this.email,
        password: this.password,
      };
      const res = await this.$store.dispatch("postUserRegister", params);
      // 注册成功跳弹窗
      if (res.status === "200") {
        if (res.code === "12005") {
          // 邮箱已被使用
          this.errorObj["email"] = this.$t("emailUsed");
        } else if (res.code === "12020") {
          // 邮箱未激活
          this.errorObj["email"] = this.$t("emailNotActive");
        } else {
          this.$toast({
            type: "success",
            message: this.$t("registerEmail", { num: this.email }),
            duration: 8,
          });
          this.$store.commit("setLoginStatus", "SignIn");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login-btn-wrap {
  padding-top: 7px;
  .login-btn {
    cursor: pointer;
    width: 100%;
    height: 39px;
    background: #111111;
    border-radius: 71px;
    font-size: 12px;
    font-family: PingFangTC-Medium, PingFangTC;
    font-weight: 500;
    color: #fff;
    text-align: center;
    line-height: 39px;
  }
}
</style>
