import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang/i18n";
import toast from "@/components/Toast/ToastIndex";
import ImageViewer from "@/components/ImagesViewer/Index";
import "./lib/kewlSensor/sensor";
import plugins from "@/plugins";
import { isMobile } from "./utils";
Vue.prototype.$toast = toast; //挂载到vue原型上
Vue.config.productionTip = false;
Vue.use(plugins);
Vue.use(ImageViewer);

// if (isMobile() && window.location.href.indexOf("8082") !== -1) {
//   var script = document.createElement("script");
//   script.src = "https://cdn.bootcss.com/vConsole/3.2.0/vconsole.min.js";
//   script.onload = function () {
//     // eslint-disable-next-line no-undef
//     new VConsole();
//   };
//   document.body.appendChild(script);
// }

if (isMobile()) {
  document.body.className = "is_mobile";
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
