<template>
  <span>
    <div class="types">
      <a class="google link" @click="LoginWith(4)">{{ $t("googleLogin") }}</a>
    </div>
    <hr :data-content="hrContent" />
    <AiInput
      :label="$t('email')"
      v-model="email"
      :placeholder="$t('placeholderEmail')"
      prop="email"
      :maxlength="50"
      :error="errorObj['email']"
      @onClearError="onClearError"
    />
    <AiInput
      :label="$t('password')"
      v-model="password"
      type="password"
      :maxlength="18"
      :placeholder="$t('password')"
      prop="password"
      :error="errorObj['password']"
      @onClearError="onClearError"
    />
    <div class="forgot-password" @click="forgotPassword">
      {{ $t("forgotPassword") }}
    </div>
    <div class="login-btn-wrap">
      <div class="login-btn" @click="!loginLoading ? handleLogin() : ''">
        {{ $t("loginTitle") }}
      </div>
    </div>
  </span>
</template>
<script>
import registerCode from "@/common/registerCode.js";
import { mapActions } from "vuex";
import { LocalDataUtil } from "@/common/util";
import { isEmail, isPassword } from "@/utils/reg_exp.js";
import AiInput from "@/components/Input/Index.vue";
export default {
  name: "SignIn",
  components: {
    AiInput,
  },
  props: {},
  watch: {},
  data() {
    return {
      email: "",
      password: "",
      errorObj: {},
      hrContent: this.$t("or"),
    };
  },
  mounted() {},
  computed: {
    loginLoading() {
      return this.$store.state.loginLoading;
    },
  },
  methods: {
    onClearError(val) {
      this.errorObj[val] = "";
    },
    forgotPassword() {
      this.$store.commit("setLoginStatus", "ChangePassword");
    },
    async handleLogin() {
      this.errorObj = {};
      if (!isEmail(this.email)) {
        this.$set(this.errorObj, "email", this.$t("placeholderEmail"));
      } else {
        this.$set(this.errorObj, "email", "");
      }
      if (!isPassword(this.password)) {
        this.$set(this.errorObj, "password", this.$t("validatePassword"));
      } else {
        this.$set(this.errorObj, "password", "");
      }
      if (this.errorObj["email"] || this.errorObj["password"]) {
        return;
      }
      const params = {
        email: this.email,
        password: this.password,
      };
      // 直接调用登录接口
      const res = await this.$store.dispatch("postUserLogin", params);
      if (res.status === "200") {
        if (res.code === "12005") {
          // 邮箱已被使用
          this.errorObj["email"] = this.$t("emailUsed");
        } else if (res.code === "12020") {
          // 邮箱未激活
          this.errorObj["email"] = this.$t("emailNotActive");
        } else if (res.code === "40020") {
          this.$toast({
            type: "error",
            message: this.$t("IncorrectEmailPassword"),
          });
        } else {
          LocalDataUtil.setItem("aispacewebtoken", res.data.token);
          LocalDataUtil.setItem(
            "aipacewebuserinfo",
            JSON.stringify(res.data.user_info)
          );
          if (res.data.user_info.invited == 0) {
            if (LocalDataUtil.getItem("registerode")) {
              this.$store.commit("setShowLogin", false);
              registerCode();
            } else {
              this.$store.commit("setLoginStatus", "Invited");
            }
          } else {
            this.$store.commit("setShowLogin", false);
            this.$router.replace("edit/community");
          }
          this.$toast({
            type: "success",
            message: this.$t("loginSuccess"),
            duration: 8,
          });
        }
      } else {
        this.$toast({
          type: "error",
          message: this.$t("IncorrectEmailPassword"),
        });
      }
    },
    ...mapActions(["getThirdLoginUrl"]),
    async LoginWith(plat) {
      // <!-- <a class="fb link" @click="LoginWith(1)">{{ $t("fbLogin") }}</a> -->
      //     <!-- <a class="apple link" @click="LoginWith(21)">{{
      //       $t("appleLogin")
      //     }}</a> -->
      const res = await this.getThirdLoginUrl(plat);
      console.info(res);
      if (res.status == 200) {
        window.location.href = res.data.redirect_url;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.forgot-password {
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: right;
  height: 12px;
  font-size: 9px;
  font-family: PingFangTC-Regular, PingFangTC;
  font-weight: 400;
  color: #060808;
  line-height: 12px;
  cursor: pointer;
  margin-top: -24px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.login-btn-wrap {
  padding-top: 7px;
  .login-btn {
    cursor: pointer;
    width: 100%;
    height: 39px;
    background: #111111;
    border-radius: 71px;
    font-size: 12px;
    font-family: PingFangTC-Medium, PingFangTC;
    font-weight: 500;
    color: #fff;
    text-align: center;
    line-height: 39px;
  }
}
.types {
  display: flex;
  flex-direction: column;
  .link {
    height: 36px;
    border-radius: 5px;
    background: #ffffff;
    border: 2px solid #e7e7e7;
    font-size: 14px;
    text-align: center;
    line-height: 36px;
    margin-bottom: 10px;
    padding-left: 34px;
    cursor: pointer;
    position: relative;
    &::before {
      content: "";
      height: 24px;
      width: 24px;
      position: absolute;
      left: 56px;
      top: 50%;
      margin-top: -12px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &.google {
      &::before {
        background-image: url(../../../assets/images/icons/icon_google.png);
      }
    }
    &.fb {
      background: #0083f5;
      border: none;
      color: #fff;
      &::before {
        background-image: url(../../../assets/images/icons/icon_fb.png);
        border-radius: 50%;
      }
    }
    &.apple {
      color: #fff;
      background: #111111;
      border: none;
      &::before {
        background-image: url(../../../assets/images/icons/icon_apple.png);
      }
    }
  }
  .google:hover {
    background: #f8f8f8;
  }
  .fb:hover {
    background: #1a94ff;
  }
}
hr {
  text-align: center;
  line-height: 0px;
  height: 1px;
  background: #e7e7e7;
  position: relative;
  margin: 23px 0;
}
hr:after {
  content: attr(data-content);
  background: white;
  z-index: 2;
  position: absolute;
  margin-top: -7px;
  width: 24px;
  height: 15px;
  font-size: 11px;
  font-weight: 400;
  color: #a9a9a9;
  line-height: 15px;
}
</style>
