import axios from "@/common/http";
import { removeOptions } from "@/utils/index";
import Toast from "@/components/Toast/ToastIndex";
let generationTimer = null; // 用于存储定时器引用
export default {
  state: () => ({
    reverseSwitch: false, // 是否支持反向词
    prompt: "", // 提示词
    negative_prompt: "", // 反向提示词
    model_type: "1", // 模型
    image: "", // 图片
    count: 4, // 生成数量
    // tips： ！！！！凡是false的都要做特殊处理
    params: {
      ar: "1:1",
      width: 512,
      height: 512,
      steps: 30,
      restore_faces: false,
      cfg_scale: 7.0,
      seed: -1,
      s: 100,
      iw: 0.25,
      sampler: "Euler a",
    }, // 扩展参数
    defaultParams: {
      ar: "1:1",
      width: 512,
      height: 512,
      steps: 30,
      restore_faces: false,
      cfg_scale: 7.0,
      seed: -1,
      s: 100,
      iw: 0.25,
      sampler: "Euler a",
    }, // 参数初始值 解决：后端bug
    imageGenerationList: [], // 用户生成图片
    hideCushionImage: false, // 是否隐藏底图
    cueWord: {}, // 提示词数据
    cueWordModal: false, // 提示词弹窗
    templateList: {}, // 模版列表
  }),
  mutations: {
    setReverseSwitch(state, value) {
      state.reverseSwitch = value;
    },
    setPrompt(state, value) {
      state.prompt = value;
    },
    setNegativePrompt(state, value) {
      state.negative_prompt = value;
    },
    setModelType(state, value) {
      state.model_type = value;
    },
    setImage(state, value) {
      state.image = value;
    },
    setCount(state, value) {
      state.count = value;
    },
    setParams(state, [field, value]) {
      state.params[field] = value;
    },
    setHideCushionImage(state, value) {
      state.hideCushionImage = value;
    },
    setParamsAll(state, value) {
      let data = JSON.parse(JSON.stringify(value));
      for (let key in state.defaultParams) {
        if (!data[key]) {
          data[key] = state.defaultParams[key];
        }
        if (key === "seed" || key === "s") {
          data[key] = parseInt(data[key]);
        }
        if (key === "iw") {
          data[key] = parseFloat(data[key]);
        }
      }
      state.params = data;
    },
    setImageGenerationList(state, value) {
      state.imageGenerationList = value;
    },
    updateImageGenerationList(state, value) {
      const image = state.imageGenerationList.find(
        (img) => img.pack_id === value.pack_id
      );
      if (image) {
        image.status = value.status;
        image.images = value.images;
      }
    },
    delImageGenerationList(state, pack_id) {
      const index = state.imageGenerationList.findIndex(
        (image) => image.pack_id === pack_id
      );
      if (index !== -1) {
        state.imageGenerationList.splice(index, 1);
      }
    },
    setCueWord(state, [category, value]) {
      state.cueWord[category] = value;
    },
    setTemplateList(state, value) {
      state.templateList = value;
    },
    setCueWordModal(state, value) {
      state.cueWordModal = value;
    },
  },
  actions: {
    async deleteImageByPackId({ commit }, pack_id) {
      // 异步删除
      commit("delImageGenerationList", pack_id);
      const res = await axios.post("/v1/aiemoji/text2image/delete", {
        pack_id,
      });
      return res;
    },
    /**
     *@desc 文生图
     */
    async postImageCreate({ dispatch, state, rootState }, that) {
      const ids = state.imageGenerationList
        .filter((image) => image.status === 0)
        .map((image) => image.pack_id);
      if (
        (rootState.userInfo["vip_level"] == 0 && ids.length >= 4) ||
        (rootState.userInfo["vip_level"] != 0 && ids.length >= 20)
      ) {
        Toast({
          type: "waring",
          message: that.$t("wordsLimitation"),
          duration: 2,
        });
        return;
      }
      let params = {
        prompt: removeOptions(state.prompt),
        negative_prompt: removeOptions(state.negative_prompt),
        model_type: parseInt(state.model_type),
        count: state.count,
        image: state.image,
        params: state.params,
      };
      const res = await axios.post("/v1/aiemoji/image/create", params);
      if (res.status === "200") {
        // state.prompt = "";
        // state.negative_prompt = "";
        if (res.code === "40016") {
          const str = res.data.join("、");
          Toast({
            type: "warning",
            message: that.$t("wordsError", { sum: str }),
            duration: 5,
          });
        } else if (res.code) {
          Toast({
            type: "error",
            message: res.msg,
            duration: 5,
          });
        } else {
          state.imageGenerationList.unshift(res.data);
          if (!generationTimer) {
            dispatch("startImageGeneration");
          }
          // 更新用户信息
          dispatch("reqGetUserInfo");
        }
      }
      return res;
    },
    /**
     *@desc 文生图作品列表
     */
    async postGenerationImageList({ commit, dispatch }, params) {
      // 解决重新登录后，没有列表检查
      if (params.page === 1) dispatch("startImageGeneration");
      const res = await axios.post("/v1/aiemoji/text2image/list", params);
      console.log(commit);
      if (res.status === "200") {
        return res.data;
      }
      return [];
    },
    /**
     *@desc 列表检查
     */
    startImageGeneration({ state, commit, dispatch }) {
      console.log("startImageGeneration");
      generationTimer = setInterval(async () => {
        if (state.imageGenerationList.length === 0) {
          clearInterval(generationTimer);
          generationTimer = null;
          return;
        }
        const ids = state.imageGenerationList
          .filter((image) => image.status === 0)
          .map((image) => image.pack_id);

        if (ids.length > 0) {
          const data = await dispatch("postGenerationImageList", {
            pack_ids: ids,
          });
          data.forEach((item) => {
            const { status } = item;
            if (status !== 0) {
              commit("updateImageGenerationList", item);
            }
          });
        } else {
          dispatch("stopImageGeneration");
        }
      }, 5000); // 每5秒钟执行一次
    },
    // 停止请求
    stopImageGeneration() {
      if (generationTimer) {
        clearInterval(generationTimer);
        generationTimer = null;
      }
    },
    // 提示词列表
    async postPromptKeyword({ commit, state }, category) {
      if (state.cueWord[category]) return state.cueWord[category];
      const res = await axios.post("/v1/prompt/keyword", { category });
      if (res.status === "200") {
        commit("setCueWord", [category, res.data]);
      }
      return state.cueWord[category] || [];
    },
    // 模板列表
    async postPromptTemplate({ commit, state }, category) {
      if (state.cueWord[category]) return state.cueWord[category];
      const res = await axios.post("/v1/prompt/template", {});
      if (res.status === "200") {
        commit("setCueWord", [category, res.data]);
      }
      return state.cueWord[category] || [];
    },
    // 提示词补全
    async postPromptLibray({ commit }, data) {
      const res = await axios.post("/v1/prompt/library", data);
      if (res.status === "200") {
        return res.data.list || [];
      }
      console.log(commit);
      return [];
    },
  },
  getters: {},
};
