import Vue from "vue";
import Vuex from "vuex";
import JSEncrypt from "jsencrypt";
import { publicKey } from "@/common/encryption";
import axios from "@/common/http";
import artfaceModule from "./modules/artface";
import emojiModule from "./modules/emoji";
import recordModule from "./modules/record";
import paymentModule from "./modules/payment";
import communityModule from "./modules/community";
import generationModule from "./modules/generation";
Vue.use(Vuex);
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
export default new Vuex.Store({
  state: {
    showLogin: false,
    // token: "",
    // token: "175089c20526a1ff9e611d00824022f5534bbb98",
    thirdLoginUrl: [],
    currentPath: "", // 当前路由
    loginStatus: "SignIn", // SignIn: 登录  SignUp注册, ResetPassword: 重置密码, ChangePassword: 找回密码 Invited 邀请码
    loginLoading: false,
    resetPassword: {
      email: "",
      resetkey: "",
    },
    userInfo: {}, // 用户信息
  },
  getters: {
    getToken: (state) => state.token,
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
    },
    setThirdLoginUrl: (state, value) => {
      state.thirdLoginUrl = value;
    },
    setShowLogin: (state, value) => {
      state.showLogin = value;
    },
    setLoginStatus: (state, value) => {
      state.loginStatus = value;
    },
    setCurrentPath: (state, value) => {
      state.currentPath = value;
    },
    setLoginLoading: (state, value) => {
      state.loginLoading = value;
    },
    setResetPassword: (state, value) => {
      state.resetPassword = value;
    },
    setUserInfo: (state, value) => {
      state.userInfo = value;
    },
  },
  /**
   * @desc 接口列表
   * @interface 邮件登录
   * @interface 邮件注册
   * @interface 图片上传
   * @interface 照片组列表
   * @interface 照片风格列表
   * @interface artface创建任务
   * @interface 任务列表
   * @interface emoji创建任务
   */
  actions: {
    // eslint-disable-next-line no-unused-vars
    async getThirdLoginUrl({ commit }, platForm) {
      const res = await axios.get("/v1/third/auth", {
        params: {
          plat: platForm,
        },
      });
      return res;
    },
    async getThirdLogonToken({ commit }, data) {
      const res = await axios.post("/v1/third/login", data);
      commit("setToken", res.data.token);
      return res;
    },
    // 登录接口
    async postUserLogin({ state }, data) {
      state.loginLoading = true;
      const params = {
        ...data,
        password: encrypt.encrypt(data["password"]),
      };
      const res = await axios.post("/v1/user/login", params);
      state.loginLoading = false;
      return res;
    },
    // 注册接口
    async postUserRegister({ state }, data) {
      state.loginLoading = true;
      const params = {
        ...data,
        password: encrypt.encrypt(data["password"]),
      };
      const res = await axios.post("/v1/user/register", params);
      state.loginLoading = false;
      return res;
    },
    // 发送重置密码接口
    async postUserSendResetPassword({ state }, data) {
      state.loginLoading = true;
      const res = await axios.post("/v1/user/send_reset_password_email", data);
      state.loginLoading = false;
      return res;
    },
    // 重置密码
    async postUserResetPassword({ state }, data) {
      state.loginLoading = true;
      const params = {
        ...data,
        password: encrypt.encrypt(data["password"]),
      };
      const res = await axios.post("/v1/user/reset_password", params);
      state.loginLoading = false;
      return res;
    },
    // 激活账号
    async postActivate({ state }, data) {
      state.loginLoading = true;
      const res = await axios.post("/v1/user/register_confirm", data);
      state.loginLoading = false;
      return res;
    },
    // 邀请码验证
    async postInvitefriendsAdd({ state }, data) {
      state.loginLoading = true;
      const res = await axios.post("/v1/invitefriends/add", data);
      state.loginLoading = false;
      return res;
    },
    // 获取用户信息
    async reqGetUserInfo({ commit }, data) {
      const res = await axios.post("/v1/user/info", data);
      if (res.status === "200") {
        commit("setUserInfo", res.data);
      }
      return res;
    },
  },
  modules: {
    artface: artfaceModule,
    emoji: emojiModule,
    record: recordModule,
    payment: paymentModule,
    community: communityModule,
    generation: generationModule,
  },
});
