/**
 * @description: 网址浏览事件埋点
 */
// 神策埋点
export const trackPage = (status, f_page_url, parm) => {
  const data = {
    status, // 1 进入 2 成功 0 退出
    page_url: window.location.href, // 页面URL
    parm, // 自定义参数
    f_page_url, //来路页面
    f_page_id: "", // 来路页面ID
  };
  if (!window["aiSensor"]) window["aiSensor"] = {};
  const aiSensor = window["aiSensor"];
  // 退出时上报
  if (status == "0") {
    const time =
      aiSensor && aiSensor["aiPageViewStartTime"]
        ? aiSensor["aiPageViewStartTime"]
        : Date.now();
    data["stay_time"] = (Date.now() - time) / 1000;
    data["progress"] = `${document.body.scrollHeight || -1}/${
      aiSensor["maxScrollY"] || -1
    }`; // 格式：最大高度 / 浏览最高高度
    console.error(aiSensor);
  } else if (status == "1") {
    aiSensor["aiPageViewStartTime"] = Date.now();
    aiSensor["maxScrollY"] = window.innerHeight;
    getScrollHeight();
  }
  console.log("上报的数据", data);
  // 神策埋点
  window.KEWLSensor.register(`ai_page_view_web`, data);
  window.KEWLSensor.track(data);
};

// 获取当前页面最前页面滚动的最大高度
// todo 单页面应用滚动
export const getScrollHeight = () => {
  window.addEventListener("scroll", () => {
    const current =
      (window.pageYOffset || document.documentElement.scrollTop) +
      window.innerHeight;
    if (window.aiSensor["maxScrollY"] < current) {
      window.aiSensor["maxScrollY"] = current;
    }
  });
};
