// 浏览器指纹 生成唯一设备ID
export const generateDeviceId = () => {
  let deviceId = localStorage.getItem("deviceId");
  if (deviceId) {
    return deviceId;
  }
  const hardware = [
    navigator.hardwareConcurrency,
    navigator.deviceMemory,
    window.screen.width,
    window.screen.height,
    window.screen.colorDepth,
    window.screen.availWidth,
    window.screen.availHeight,
    window.screen.pixelDepth,
    navigator.plugins.length,
  ].join("");
  const userAgent = navigator.userAgent;
  const deviceData = `${hardware}_${userAgent}`;
  deviceId = btoa(deviceData);
  localStorage.setItem("deviceId", deviceId);
  return deviceId;
};

// 获取设备
export const getOS = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  let os = "Unknown";
  if (platform === "Win32" || platform === "Win64") {
    os = "Windows";
  } else if (platform === "MacIntel") {
    os = "Mac OS X";
  } else if (platform === "Linux" || platform === "Android") {
    os = "Linux";
  }
  const regex = /(Windows Phone|Windows Mobile|Android|iPad|iPhone|iPod)/i;
  const match = userAgent.match(regex);
  if (match) {
    os = match[1];
  }
  return os;
};

// 1：安卓APP 2：IOS-APP 3：PC 4：安卓H5 5：IOS-H5
export const getDeviceType = () => {
  const userAgent = navigator.userAgent;
  if (userAgent.match(/Android/i) && window.Android) {
    return "1";
  } else if (
    userAgent.match(/iPhone|iPod/i) &&
    window.webkit &&
    window.webkit.messageHandlers
  ) {
    return "2";
  } else if (userAgent.match(/Windows|Macintosh|Linux|Ubuntu/i)) {
    return "3";
  } else if (userAgent.match(/Android/i)) {
    return "4";
  } else if (userAgent.match(/iPhone|iPod/i)) {
    return "5";
  } else {
    // 未知设备
    return "6";
  }
};

// 系统版本
export const getSystemVersion = () => {
  const platform = navigator.userAgent.toLowerCase();
  let systemVersion = "";
  const userAgent = navigator.userAgent;
  if (/Android/.test(userAgent)) {
    systemVersion = userAgent.match(/Android\s([0-9.]+)/)[1];
  } else if (/iPhone|iPad|iPod/.test(userAgent)) {
    systemVersion = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/)[1];
  } else if (/Windows NT/.test(userAgent)) {
    systemVersion = userAgent.match(/Windows NT (\d+\.\d+)/)[1];
  } else if (/Macintosh/.test(userAgent)) {
    systemVersion = userAgent.match(/Mac OS X (\d+[._]\d+[._]?\d*)/)[1];
  } else if (/Linux/.test(userAgent)) {
    systemVersion = "Linux";
  } else if (platform.indexOf("win") !== -1) {
    systemVersion = userAgent.match(/Windows NT (\d+\.\d+)/i)[1];
  } else if (platform.indexOf("mac") !== -1) {
    systemVersion = userAgent.match(/Mac OS X (\d+[._]\d+[._]?\d*)/i)[1];
  } else if (platform.indexOf("linux") !== -1) {
    systemVersion = userAgent.match(/Linux/i)[0];
  }
  return systemVersion;
};

export const getConnectionType = () => {
  if (!navigator.onLine) {
    return 0; // 无网络连接
  }
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  if (connection) {
    const type = connection.effectiveType;
    if (type === "wifi") {
      return 1; // WiFi连接
    } else if (
      type === "cellular" ||
      type === "4g" ||
      type === "3g" ||
      type === "2g"
    ) {
      return 2; // 数据连接
    }
  }
  return -1; // 未知类型
};
