<template>
  <span>
    <button
      @click="$emit('click')"
      :class="{
        'ai-btn': true,
        'btn-black': type === 'primary',
        'btn-white': type === 'default',
        'btn-blue': type === 'blue',
        'ai-btn-disabled': disabled || loading,
      }"
      type="button"
      :style="{
        width: `${width}px`,
        height: `${height}px`,
        lineHeight: `${height}px`,
        borderRadius: `${radius}px`,
        fontSize: `${fontSize}px`,
      }"
    >
      <slot></slot>
      <div v-if="loading" class="btn-loading"></div>
    </button>
  </span>
</template>
<script>
export default {
  name: "AiSpaceButton",
  components: {},
  props: {
    // btn 类型
    //    primary  黑底 白字
    //    default  白底 黑字
    //    blue     蓝底 白字
    type: {
      type: String,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 440,
    },
    height: {
      type: [Number, String],
      default: 42,
    },
    radius: {
      type: [Number, String],
      default: 10,
    },
    fontSize: {
      type: [Number, String],
      default: 14,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style lang="less" scoped>
.ai-btn {
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.ai-btn:active,
.ai-btn:hover {
  // opacity: 0.9;
}

.ai-btn-disabled {
  opacity: 0.9;
  cursor: not-allowed;
}
.btn-black {
  background: #111111;
  color: #fff;
}
.btn-white {
  color: #2d2d2d;
  background: #fff;
  border: 1px solid #e7e7e7;
}
.btn-blue {
  color: #fff;
  background: #5564fe;
}
.btn-blue:hover {
  background: #4752bf;
}

.btn-loading {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 14px;
  top: 50%;
  margin-top: -7px;
  animation: spin 2s linear infinite;
  background: url("../../assets/images/icons/icon_btn_loading.png");
  background-size: 100% 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
