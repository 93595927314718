import Vue from "vue";
import VueI18n from "vue-i18n";
import messages_zh from "./zh";
import messages_en from "./en";

Vue.use(VueI18n);

// 根据浏览器语言决定默认语言
function getInitialLocale() {
  const browserLocale = navigator.language || navigator.userLanguage; // 获取浏览器语言
  // 可在此处理最终展示语言
  localStorage.setItem("Language", browserLocale);
  return browserLocale;
}

const i18n = new VueI18n({
  locale: getInitialLocale(),
  messages: {
    zh: messages_zh,
    en: messages_en,
  },
});

export default i18n;
