<template>
  <transition name="login-fade">
    <div v-show="$store.state.showLogin" class="login-dialog-container">
      <div
        v-show="$store.state.showLogin"
        class="mask"
        @click="showLogin"
      ></div>
      <div class="login-dialog">
        <div class="btn-close" @click="showLogin"></div>
        <!-- 登录注册 -->
        <div v-if="loginStatus === 'SignIn' || loginStatus === 'SignUp'">
          <div class="title-wrap">
            <div
              @click="handleSwitch('SignIn')"
              :class="{ 'title-current': loginStatus === 'SignIn' }"
              class="title"
            >
              {{ $t("loginTitle") }}
            </div>
            <div
              @click="handleSwitch('SignUp')"
              class="title"
              :class="{ 'title-current': loginStatus === 'SignUp' }"
            >
              {{ $t("signUp") }}
            </div>
          </div>
          <!-- 登录内容 -->
          <div v-if="loginStatus === 'SignIn'">
            <SignIn />
          </div>
          <!-- 注册内容 -->
          <div v-if="loginStatus === 'SignUp'">
            <SignUp />
          </div>
          <p class="tips">
            {{ $t("loginTips1") }}
            <a href="/protocol/terms.html">{{ $t("loginTips2") }}</a>
            {{ $t("loginTips3") }}
            <a href="/protocol/privacy.html">{{ $t("loginTips4") }}</a>
          </p>
        </div>
        <div v-if="loginStatus === 'ChangePassword'">
          <div class="title-wrap">
            <div class="title-modla">
              {{ $t("changeTitle") }}
            </div>
          </div>
          <ChangePassword />
        </div>
        <div v-if="loginStatus === 'ResetPassword'">
          <div class="title-wrap">
            <div class="title-modla">
              {{ $t("resetTitle") }}
            </div>
          </div>
          <ResetPassword />
        </div>
        <div v-if="loginStatus === 'Invited'">
          <div class="title-wrap">
            <div class="title-modla">
              {{ $t("invitationCode") }}
            </div>
          </div>
          <InvitationCode />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SignIn from "@/components/Home/LoginComponents/SignIn.vue";
import SignUp from "@/components/Home/LoginComponents/SignUp.vue";
import ChangePassword from "@/components/Home/LoginComponents/ChangePassword.vue";
import ResetPassword from "@/components/Home/LoginComponents/ResetPassword.vue";
import InvitationCode from "@/components/Home/LoginComponents/InvitationCode.vue";
export default {
  name: "LoginDialog",
  components: {
    SignIn,
    SignUp,
    ChangePassword,
    ResetPassword,
    InvitationCode,
  },
  data() {
    return {};
  },
  computed: {
    loginStatus: {
      get() {
        return this.$store.state.loginStatus;
      },
      set(value) {
        this.$store.commit("setLoginStatus", value);
      },
    },
  },
  methods: {
    handleSwitch(status) {
      this.loginStatus = status;
    },
    showLogin() {
      // if (this.loginStatus === "Invited") return;
      this.$store.commit("setShowLogin", false);
      let that = this;
      if (this.loginStatus !== "Invited") {
        var timerId = setTimeout(function () {
          that.loginStatus = "SignIn";
          clearTimeout(timerId);
        }, 300);
      }
      this.$router.replace("/");
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.login-dialog {
  width: 357px;
  min-height: 270px;
  background: #ffffff;
  border-radius: 9px;
  position: absolute;
  padding: 30px 42px;
  color: #000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .title-wrap {
    display: flex;

    .title {
      cursor: pointer;
      font-size: 20px;
      margin-bottom: 23px;
      margin-right: 18px;
      font-size: 18px;
      font-weight: 400;
      color: #060808;
    }

    .title-modla {
      font-size: 20px;
      font-size: 18px;
      font-weight: 400;
      color: #060808;
      margin-bottom: 13px;
      font-weight: 600;
    }

    .title-current {
      font-weight: 600;
    }
  }

  .btn-close {
    height: 48px;
    width: 48px;
    position: absolute;
    right: 0;
    top: 0;
    background: url(../../assets/images/iconsV2/icon_modal_close.png) no-repeat
      center transparent;
    background-size: 12px;
    cursor: pointer;
  }

  .input-title {
    height: 15px;
    font-size: 11px;
    font-weight: 400;
    color: #060808;
    line-height: 15px;
  }

  .tips {
    font-size: 12px;
    opacity: 0.6;
    text-align: center;
    margin-top: 10px;
    padding: 18px 20px 0 16px;

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.mask,
.login-dialog-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(16px);
}

.login-dialog-container {
  z-index: 999;
}

.login-fade-enter-active,
.login-fade-leave-active {
  transition: all 0.5s ease;
  transform: translateY(0);
}

.login-fade-enter,
.login-fade-leave-active {
  opacity: 0;
  transform: translateY(-50%);
}
</style>
