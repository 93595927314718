export default {
  // sidebar
  sidebarAiArtface: "AI ArtFace",
  sidebarAiEmoji: "AI Emoji",
  sidebarGenerateRecord: "Generate Record",
  sidebarSignout: "退出",
  sidebarSignIn: "登录 / 注册",
  sidebarNotifications: "通知",
  sidebarLiked: "点赞记录",
  sidebarOther: "其他",
  sidebarAiGeneration: "AI图像生成",
  sidebarUserTools: "用户工具",
  sidebarCommunityFeed: "社区",
  sidebarStartHere: "从这里开始",
  sidebarInviteFriends: "邀请好友",

  // home
  artFaceDesc: "🥳魔法头像将您变成另一个自己，让您的头像更加个性化。",
  emojiDesc: "🤪魔法表情包让您的魔法头像动起来，让您的表情更加有趣。",
  createNow: "立即创作",
  igniteCreativity: "点燃创意",
  igniteCreativityDesc: "让AI助力，提升创意效率，绘就惊艳之作！",
  betaBtn: "🎉申请内测",
  help: "帮助",
  helpContact: "联系 support@aispark.ai",
  aboutThePlatform: "关于平台",
  magicBanner1: "AI试衣",
  magicBanner1Desc:
    "人工智能自动换装，一张模特图适用百件商品，升级您的产品图片，省去拍摄、时间成本。",
  magicBanner2: "AI超模",
  magicBanner2Desc:
    "人工智能生成的模型和背景，升级您的产品图片，省去拍摄、时间成本。",
  magicBanner3: "AI商品图",
  magicBanner3Desc: "AI生成海量场景图，只需点击几下，即可生成电商商品图片。",
  magicBanner4: "AI绘画",
  magicBanner4Desc: "在几分钟内，创作出令人惊叹的原创艺术作品。",

  // upload
  uploadTopic: "AI ArtFace",
  uploadTitle: "Upload photos and select styles to generate exclusive avatars.",
  uploadTips1: "Add 4-15 photos of the same person",
  uploadTips2: "Click here or drag and drop the photos to upload",
  uploadTips2Disabled: "You can continue to add after deleting pictures",
  uploadTips3: "上传图片即表示您同意我们的",
  uploadTips5: "Add {num} photos to proceed",
  uploadTips7: "Upload limit reached, please select the avatar styles",
  createAvatar: "Generate Avatar",
  createAvatarStyle: "Continue with {num} style(s)",
  insufficientPhotos: "图片数量不够",
  insufficientPhotosTips: "{num1}张图片不符合标准，请至少添加{num2}张",
  success: "成功",
  fail: "失败",
  inadequatePhotos: "Please add at least {num} more",

  viewAll: "View All",
  // homepage 中间五张彩色卡片切换swiper;
  homeCircleTitle1: "创建Instagram, TikTok等社媒内容",
  homeCircleTitle2: "创建专业的个人资料头像",
  homeCircleTitle3: "个性的自拍，找到你的真爱	",
  homeCircleTitle4: "制作专属表情包",
  homeCircleTitle5: "像超级英雄一样获得胜利",
  // recordList
  recordListTitle: "生成记录",
  recordListTitleDesc:
    "每次人工智能（AI）都会生成独特的头像。永远不会有相同的结果",
  recordImgNum: "张", //xx张
  recordGenerate: "正在创建", //正在创建
  aiTypes0: "AI Avatars",
  aiTypes1: "AI Emojis",
  // recordAbout: "预计还需", //预计还需
  // recordMinLeft: "分钟",
  recordMinLeft: "预计还需 {num} 分钟",
  createBeatufy: "生成美好，需要时间呵护...",
  recordCheckDetail: "查看详情",
  btnStatusDesc0: "刷新进度",
  btnStatusDesc1: "制作AI Emojis",
  btnStatusDesc2: "重新生成",
  btnStatusDesc100: "立即支付",
  payNum: "支付{num}",
  noDataRecords: "暂无记录",
  //edit/emoji
  editEmojiDesc: "先制作AI头像，再制作AI表情，让您的头像动起来。",
  createAIEmoji: "制作 Emoji",
  quantity: "数量",
  downloads: "下载",
  downloadAll: "下载全部",
  selectAll: "选择全部",
  deselectAll: "取消全选",
  selected: "已选（{num}），生成AI Emoji",
  works: "张",
  stepCreate: "第1步：制作AI Avatar",
  step: "第 {num} 步",
  stepTips: "首先为您制作AI Avatar，然后您继续下一步制作AI Emoji。",
  gotIt: "知道了",
  //homepage cardCircle
  cardArtStylesTopTitle: "50+ Realistic and artistic style",
  cardArtStylesBelowTitle: "suitable for any scene",
  uploadTerms: "用户条款",
  uploadTips4: "和",
  uploadPrivacy: "隐私政策",
  goodExamples: "优秀案例",
  badExamples: "错误案例",
  // Tabs
  Hot: "热门",
  New: "最新",
  // Generation page
  addNegativePrompt: "添加否定提示词",
  generation: "生成",
  generationHistory: "历史记录",
  deleteAll: "删除全部",
  generateAgain: "重新生成",
  numberOfImages: "图片数量",
  imageDimensions: "图片尺寸",
  imageToImage: "图生图",
  advanced: "高级",
  created: "创建于",
  close: "关闭",
  open: "开启",
  width: "宽度",
  height: "高度",
  steps: "步数",
  scale: "文本强度",
  faces: "面部修复",
  uploadTips6: "添加 {num} 张图片",
  uploadTips8: "将文件拖到此处，或点击上传用于输入",
  uploadTips9: "隐私不可见",
  uploadTips10: "来自作者的参考图",
  uploadTips11: "点击上传用于输入",
  modelName1: "Midjourney风格",
  modelName2: "人物 1.0",
  modelName3: "设计素材 1.0",
  modelName4: "室内设计 1.0",
  estimatedMinutes: "预计 {num} 分钟",
  generationErrorTips01: "无效参数",
  generationErrorTips02: "请调整提示词后重试或稍后重试",
  delModalTitle: "确定要删除生成记录么？",
  delModalDesc: "删除后将无法恢复",
  cancel: "取消",
  delete: "删除",
  inputPrompt: "输入提示词",
  inputTipsPrompt: "提示词无效，请重新输入。",
  inputNegativePrompt: "输入不想在图像中看到的内容（反向提示词）",
  sameStyle: "制作同款",
  ForwardPromptDetails: "正向提示词:",
  ReversePromptDetails: "反向提示词:",
  publishWork: "发布到社区",
  release: "发布",
  title: "作品名称",
  titlePlaceholder: "请输入作品名称",
  copySuccess: "复制成功",
  wordsError: "抱歉，您输入的信息中包含敏感词：{sum}，请重新输入",
  wordsLimitation: "生成中，请稍后重试",
  iw: "图片权重",
  s: "风格化",
  seed: "随机种子",
  sampler: "采样器",
  iwTips: "生成结果，与参考图和描述文字的相似程度",
  sTips: "数值越小越极简扁平，数值越大越复杂",
  samplerTips:
    "采样器是图片生成的方法，DPM++系列，图片质量更好，Euler 和 DDIM，生成速度更快",
  seedTips: "采用固定的随机种子，下次生成图片时最大限度的进行还原。",
  stepsTips: "步数更高-质量更高，但速度较慢，过30效果递减，建议保持默认",
  scaleTips: "值越高，生成图像越接近你输入的提示词",
  // 登录注册
  signUp: "注册",
  loginTitle: "登录",
  confirmChange: "确认更改",
  email: "邮箱",
  placeholderEmail: "请输入邮箱",
  password: "密码",
  googleLogin: "谷歌登录",
  confirmPassword: "确认密码",
  loginTips1: "注册即表示您同意",
  loginTips2: "服务条款",
  loginTips3: "和",
  loginTips4: "隐私政策",
  forgotPassword: "忘记密码",
  resetTitle: "更改您的密码",
  changeTitle: "找回密码",
  validatePassword: "6-18位，必须包含数字和字母",
  validatePasswordConfirm: "密码不一致，请检查后重试",
  revisePassword: "链接已发送至{num}，前往邮箱点击链接，完成密码修改~",
  registerEmail: "登录链接已发送至{num}，快去激活吧~",
  emailUsed: "邮箱已被使用",
  emailNotActive: "邮箱未激活，请前往邮箱点击链接激活。",
  IncorrectEmailPassword: "邮箱或密码错误",
  accountActivationSuccessful: "账号激活成功",
  emailNotRegistered: "邮箱未注册",
  loginSuccess: "登录成功",
  PayYearly: "按年支付（8折）",
  PayMonthly: "按月支付",
  or: "或",
  switchAccount: "更换其他账号",
  // 提示词分类
  style: "风格",
  art: "样式",
  view: "视角",
  light: "光照",
  material: "材质",
  render: "渲染",
  quality: "图像优化",
  template: "模板",
  // 提示词多语言
  randomPrompt: "随机提示词",
  featuredModels: "选择模型",
  promptStudio: "提示词助手",
  prompt: "提示词",
  promptPlaceholder: "请选择提示词或模板",
  promptTips: "“+”为强调，“-”为弱化",
  pictureSize: "图片尺寸",
  placeholderTxt: "请输入内容",
  // common question
  subscribe: "订阅",
  subscribed: "已订阅",
  currently: "您当前是",
  freeVersion: "免费版",
  basicVersion: "基础版",
  foreverFree: "永久免费",
  billedMonthly: "按月计费",
  billedYearly: "按年计费",
  monthly: "每月",
  yearly: "每年",
  support1: "每月200积分",
  support2: "每月1600积分",
  support22: "每月400积分",
  support3: "低优先级生成",
  support4: "高优先级生成",
  support5: "会员专属标识",
  support6: "抢先体验新功能",
  support7: "访问全部功能",
  support8: "排队最多4张图片",
  support9: "排队最多20张图片",
  manageSubscription: "管理订阅",
  cancelPlan: "取消订阅",
  cancelPlanTips: "很遗憾你要取消订阅",
  canceled: "已取消订阅",
  generateCreadits: "生成（将消耗{num}积分）",
  upgrade: "升级",
  subscribedTitle: "管理订阅",
  subscribedDesc: "升级解锁，释放您的艺术潜力。",
  questionsTitle: "常见问题",
  questionSubTitle: "找不到您要了解的问题？发送邮件至 📮support@aispark.ai",
  question1: "如何应用积分？",
  answer1:
    "每2个积分生成一张图片，生成图片的积分消耗，根据您使用的参数和工具不同来决定。'生成' 按钮上，会显示每一次生成需要消耗的积分。",
  question2: "我怎样可以获得积分？",
  answer2: "您可以：1.订阅，2.每月可免费获得200积分",
  question3: "我可以更改我的订阅么？",
  answer3:
    "是的，您可以随时取消。您的计划将在续订日期之前保持有效，之后它将更改为免费计划。",
  question4: "积分会过期吗？",
  answer4: "积分在每个续订期后重置。如果您取消订阅，积分将在续订日期到期。",
  question5: "我可以获得退款么？",
  answer5: "抱歉，由于服务性质，我们无法提供退款。",
  invitationCode: "邀请码",
  confirm: "确定",
  placeholderCode: "请输入邀请码",
  moreSettings: "更多设置",
  // invite friends
  myInvitationCode: "我的邀请码",
  copyInvitationCode: "复制邀请链接",
  invitationRecord: "邀请记录",
  nickname: "昵称",
  registrationTime: "注册时间",
  invitedNum: "邀请人数",
};
